import { defineStore } from 'pinia'


export const usePagesStore = defineStore({
  id: "pagesStore",

    state: () => ({
      data: {
        loading: true,
        visible: '',
        category: '',
        customURL: '',
        showPopup: false,
        popupType: '',
        selectedSituationGroup: '',
        selectedTimetablePoint: '',
        imageDetail: '',
        imageDetailText: '',
        fullScreenMap: false,
        mapType: 'standard'
    }
  }),

  actions: {

    setLoading(status) {
      this.data.loading = status
    },

    getLoading() {
      return this.data.loading
    },

    setPageVisible(page) {
      this.data.visible = page
    },

    getPageVisible() {
      return this.data.visible
    },

    setCategory(category) {
      this.data.category = category
    },

    getCategory() {
      return this.data.category
    },

    setVisiblePopup(value) {
      this.data.showPopup = value
    },

    getVisiblePopup() {
      return this.data.showPopup
    },

    setPopupType(value) {
      this.data.popupType = value
    },

    getPopupType() {
      return this.data.popupType
    },

    setSelectedSituationGroup(value) {
      this.data.selectedSituationGroup = value
    },

    getSelectedSituationGroup() {
      return this.data.selectedSituationGroup
    },

    setCustomUrl(url) {
      this.data.customURL = url
    },

    getCustomUrl() {
      return this.data.customURL
    },
    
    setImageDetail(url) {
      this.data.imageDetail = url
    },

    getImageDetail() {
      return this.data.imageDetail
    },
    
    setImageDetailText(text) {
      this.data.imageDetailText = text
    },

    getImageDetailText() {
      return this.data.imageDetailText
    },    
    
    setFullScreenMap(status) {
      this.data.fullScreenMap = status
    },

    getFullScreenMap() {
      return this.data.fullScreenMap
    },
    
    setMapType(maptype) {
      this.data.mapType = maptype
    },
    
    getMapType() {
      return this.data.mapType
    }
  }
});
