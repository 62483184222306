<template>
  <div class='container_startlistriderinfo'>
    <div class='head'>
      <div class='thumb'>
        <img v-if="hayThumb()" :src="startlist.selectedRider.data.sum.thu">
        <div v-else><span class='title_bib' style="color:#AAA;padding:20px;font-size:30px !important;">{{ startlist.selectedRider.dor }}</span></div>
      </div>
      <div class='title'>
        <span v-if="hayThumb()" class='title_bib' :style="color12">{{ startlist.selectedRider.dor }}</span>
        <div class='title_name'>
          <img v-if="startlist.selectedRider.pai" class="country" :src="require(`@/assets/flags/${startlist.selectedRider.pai}.png`)" />
          <span><span style='font-weight:none;'>{{ startlist.selectedRider.nfu }}</span> {{ startlist.selectedRider.afu.toUpperCase() }}</span>
        </div>
        <div class='title_data'>
          <span class='team' v-if="startlist.selectedRider.neq !== undefined && startlist.selectedRider.neq && startlist.selectedRider.neq != ''">
            <span class="thumb_team" v-if="this.getTeam(this.startlist.selectedRider).thu"><img :src="this.getTeam(this.startlist.selectedRider).thu"></span>
            <span class="name_team">{{ startlist.selectedRider.neq }}</span>
            <!--<span v-if="startlist.selectedRider.neq == 'Bahrain Victorious'" class="bike"><img src="https://tracker.helmuga.cloud/resources/sponsors/merida.svg"></span>-->
          </span>
          <span 
            class='team'
            v-if="startlist.selectedRider.data.sum !== undefined && startlist.selectedRider.data.sum.fec != '' && startlist.selectedRider.data.sum.fec != '1970-01-01'" 
          >{{ startlist.selectedRider.data.sum.fec }} ({{ getAge(startlist.selectedRider.data.sum.fec) }})</span>
        </div>
      </div>          
    </div>
    <div class="wrapper_menu" v-if="typeof this.config.getConfig().general.flags === 'undefined' || this.config.getConfig().general.flags == '0'">
      <div class='menu'>
        <div 
          v-if="startlist.selectedRider.data.inr !== undefined && startlist.selectedRider.data.inr.length > 0 && this.config.getConfig().general.race_filter != ''"
          :class="{ menu_item_active: this.visible == 'inrace', menu_item: this.visible != 'inrace' }" 
          @click="clickMenu('inrace')">{{ this.config.getConfig().general.race_filter }}
        </div>
        <div 
          v-if="startlist.selectedRider.data.res !== undefined && startlist.selectedRider.data.res.length > 0"
          :class="{ menu_item_active: this.visible == 'results', menu_item: this.visible != 'results' }" 
          @click="clickMenu('results')">{{ $t('best_results') }}
        </div>
        <div 
          v-if="startlist.selectedRider.data.tea !== undefined && startlist.selectedRider.data.tea.length > 0"
          :class="{ menu_item_active: this.visible == 'teams', menu_item: this.visible != 'teams' }" 
          @click="clickMenu('teams')">{{ $t('teams') }}
        </div>
      </div>  
    </div>
    <div v-if="startlist.selectedRider.data.inr !== undefined" class='content'>
      <div class='tabla' id='tabla_rider_info'>
        <div class='wrapper_results' v-if="this.visible == 'inrace' && startlist.selectedRider.data.inr.length > 0">
          <StartListRiderInfoResultsRace
            v-for="(results, index) in startlist.selectedRider.data.inr"
            :key="index"
            :results="results"
          >
          </StartListRiderInfoResultsRace>
        </div>
        <div v-if="this.visible == 'inrace' && startlist.selectedRider.data.inr.length == 0">
          <span class='message'>{{ $t('first_participation') }}</span>
        </div>
        <div class='wrapper_results' v-if="this.visible == 'results'">
          <StartListRiderInfoResults
            v-for="(results, index) in startlist.selectedRider.data.res"
            :key="index"
            :results="results"
          >
          </StartListRiderInfoResults>
        </div>

        <div class='wrapper_results' v-if="this.visible == 'teams'">
          <StartListRiderInfoTeams
            v-for="(teams, index) in startlist.selectedRider.data.tea"
            :key="index"
            :teams="teams"
          >
          </StartListRiderInfoTeams>
        </div>
      </div>
    </div>

    <div class="result_point_container" v-if="typeof this.config.getConfig().general.flags !== 'undefined' && this.config.getConfig().general.flags == '1'">

      <div class="header">
        <div class="header_item">{{ $t('point') }}</div>
        <div class="header_item">{{ $t('position') }}</div>
        <div class="header_item">{{ $t('result') }}</div>
      </div>
      <div
        class="container"
        v-for="(classification, index) in this.filterClassificationMenus()"
        :key="index"
        :classification="classification.num"
      > 
            <div class="" style="height:15px;"><img style="width:15px;height:15px;" :src="imgPunto(classification.num)" /></div>
            <div class="position">{{ getRes(classification).pos }}</div>  
            <div class="result" v-if="getRes(classification).tim">{{ aMinSegundos(getRes(classification).tim) }} <span v-if="getRes(classification).pos != 1">({{ getRes(classification).res }})</span></div>
      </div>
    </div>

    <div v-if="this.startlist.stats?.countries" class="credits"><span>{{ $t('more_info') }} <a href='https://firstcycling.com' target='_blank'>firstcycling.com</a></span></div>
  </div>
</template>

<script>
  import { useStartlistStore } from '@/store/startlist'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import { useUpdateStore } from '@/store/update'
  import { useResultsStore } from '@/store/results'
  import StartListRiderInfoResults from '@/components/StartListRiderInfoResults'
  import StartListRiderInfoResultsRace from '@/components/StartListRiderInfoResultsRace'
  import StartListRiderInfoTeams from '@/components/StartListRiderInfoTeams'

  export default {
    name: 'start-list-rider-info',

    data() {
      return {
        visible: ''
      }
    },

    props: {
      source: String
    },

    setup() {
      const startlist = useStartlistStore()
      const config = useConfigStore()
      const pages = usePagesStore()
      const update = useUpdateStore()
      const results = useResultsStore()

      const routeColor = function() {
        return config.getConfig().general.color1
      };

      return {
        startlist,
        config,
        update,
        pages,
        results
      }
    },

    mounted() {
      if(this.config.getConfig().general.race_filter != "")
        this.visible = "inrace"
      else
        this.visible = "results"
    },

    methods: {   
      
      imgPunto(num) {
        if(num == 0)
          return require(`@/assets/images/finish.png`)
        else
          return require(`@/assets/images/time_${num}.png`)
      },  

      aMinSegundos(milisegundos) {
        const value = milisegundos / 1000;
        const sec = parseInt(value, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        if(hours > 0)
          return hours + ":" + minutes + ':' + seconds;
        else
          return minutes + ':' + seconds;
      },
      
      getRes(classification2) {
        const classification = classification2.tip
        const selectedRider = this.startlist.selectedRider;

        const raceItem = this.update.getResultsLiveTiming()
          .find(item => item.raceid == classification);
        
        if (!raceItem) {
          return "No race item found";
        }

        const riderItem = raceItem.riders
          .find(item => item.dor === selectedRider.dor);

        if (!riderItem) {
          return "No rider item found";
        }

        return riderItem || "No result found";
      },
    
      filterClassificationMenus() { 

        var arrayMenus = []
        var arrayReturn = []
        var arrayReturnResults = []
        var arrayReturnLiveTiming = []
        var i = 0
        let categoria = this.startlist.selectedRider.neq

        /*this.config.getClassifications().forEach(element => {
            if(element.tip <= -1)
              arrayReturnLiveTiming.push(element)
        })*/


        this.update.getResultsFinal().forEach(element => {
          if(((element.tip == "0") && element.riders.length > 0) || (element.tip != "0")) {
            arrayMenus.push(element.tip)
          }
        })

        arrayReturnResults = this.multiFilter(this.config.getClassifications(), { tip: arrayMenus})
        arrayReturn = arrayReturnLiveTiming.concat(arrayReturnResults)

        if(arrayReturn.length > 0 && this.results.getFilterClassification() == "") {
          this.results.setFilterClassification(arrayReturn[0].tip)
        }

        console.log(arrayReturnLiveTiming)
        console.log(arrayReturnLiveTiming.concat(arrayReturnResults).filter((item) => item.cat == categoria))

        return arrayReturnLiveTiming.concat(arrayReturnResults).filter((item) => item.cat == categoria)

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      clickMenu(option) {
        this.visible = option
      },
      
      hayThumb() {
        return this.startlist.selectedRider.data.sum !== undefined && this.startlist.selectedRider.data && this.startlist.selectedRider.data.sum.thu != ''
      },

      getAge(date) {
        var dob = new Date(date)
        var month_diff = Date.now() - dob.getTime()
        var age_dt = new Date(month_diff)
        var year = age_dt.getUTCFullYear()
        var age = Math.abs(year - 1970)

        return age
      },      
      
      getTeam(rider) {
        //if(typeof code[0].thu === 'undefined')
        //  return ""

        if(rider.eco != "")
          return this.startlist.getTeams().filter((team) => team.uci == rider.eco)
        else
          return this.startlist.getTeams().filter((team) => team.nom == rider.neq)

      }

    },

    components: {
      StartListRiderInfoResults,
      StartListRiderInfoResultsRace,
      StartListRiderInfoTeams
    },

    computed: {
      color12() {
        return {
          //"color": `${this.config.getConfig().general.color1}`
          "color": "#AAA"
        }
      },
    },

}
</script>

<style scoped>

  @media (max-width: 768px) {

    .content {
      height: calc(100% - 210px) !important;
    }
    
    .tabla {
      height: 100% !important;
    }

    .thumb img {
      height: 100px !important;
    }

    .head {
      margin-top: 10px !important;
    }

    .title_name {
      font-size: 15px !important;
    }

    .team {
      font-size: 12px !important;
    }

    .title_bib {
      font-size: 25px !important;
    }

    .credits {
      width: calc(100% - 10px) !important;
    }

  }

  .content {
    height: calc(100% - 190px);
  }

  .container_startlistriderinfo {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto;
    padding: 12px 10px;
    font-size: 14px;
    width: calc(100% - 22px); 
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    background: white;
  }

  .head {
    height: 100px;
    display: flex;
    align-items: center;
  }

  .thumb {
    border-right: 1px solid #dbdbdb;
    margin-right: 20px;
  }

  .result_point_container {
    display: flex;
    flex-direction: column;
  }

  .result_point {
    display: flex;
    gap: 5px;
    align-items: center;    
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  .header {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto;
    padding: 6px 10px;
    font-size: 14px;
    width: calc(100% - 22px); 
    border: 1px solid #dbdbdb;
    background: #fafafa;
  }

  .header_item {
    font-size: 10px;
    text-transform: lowercase;
    color: #AAA;
    padding-left: 5px;
  }  

  
  .credits {
    height: 20px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 20px);
  }

  .credits a {
    color: #333;
    text-decoration: none;
  }

  .credits a:visited {
    color: #333;
  }

  .thumb img {
    height: 100px;
    padding-right: 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .thumb_team {
    height: 17px;
    margin-right: 5px;
  }

  .thumb_team img{
    height: 17px;
  }

  .title {
    display:flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .title_bib {
    font-size: 30px;
  }

  .title_name {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size:20px;
    font-weight: bold;
  }

  .title_name img {
    height: 15px;
    margin-right: 10px;
  }

  .title_data {
    display: flex;
    flex-direction: column;
    color: #dbdbdb;
    font-size: 12px;
    margin-top: 2px;
    letter-spacing: 1px;
  }

  .team {
    text-align: left;
    color: #777;
    font-size: 12px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .wrapper_menu {
    height: 50px;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .menu {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .menu_item {
    padding: 0px 25px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    flex-basis: 80px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
  }

  .menu_item_active {
    flex-basis: 80px;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0px 25px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    color: white;
    background: linear-gradient(270deg,#ff2882,#0087d2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 45px;
  }

  .menu_item:hover:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(270deg,#ff2882,#0087d2);
  }

  .country {
    border: 1px solid #eee;
  }

  .tabla {
    overflow: auto;
    height: 100%;
  }

  .wrapper_results {
    border-top: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }

  .close {
    display: none;
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 10px;
    border: 1px solid #dbdbdb;
  }
  
  .bike {
    display: flex;
    height: 10px;
  }

  .bike img {
    margin-left: 5px;
    height: 10px;
  }


</style>