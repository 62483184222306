<template>
  <div class="container_grupo" @click="abrirPopup()">
    <div v-if="group.riders.length > 0" class="numero_corredores">{{ group.riders.length }}</div>
    <div class="grupo">
      <div class="subgrupo">
        <div class='small' :style="color1" v-if="group.idg < 2">{{ nombreGrupo(group.idg) }}</div>
        <div class='big' v-if="id == '0'">
          <span class='big_img'><img :src="require(`@/assets/images/finish.png`)"></span>
          <span>{{ kmsAMeta() }} km</span>
        </div>
        <div class="big" v-if="group.sgr > 0">{{ aMinSegundos(group.sgr) }}</div>
      </div>
      <div class="subgrupo" v-if="group.nco > 0">
        <situation-group-rider
          v-for="(rider, index) in datosStartlist"
          :key="index"
          :rider="rider"
        ></situation-group-rider>
      </div>
      <div class="subgrupo unknown" v-if="group.ncd > 0 && group.nco > 0">
        + {{ group.ncd }} {{ $t('riders_situation' +  this.config.getGenere()) }}
      </div>
      <div class="subgrupo unknown" v-if="group.ncd > 0 && group.nco == 0">
        {{ group.ncd }} {{ $t('riders_situation' +  this.config.getGenere()) }}
      </div>
    </div>
  </div>
</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import SituationGroupRider from '@/components/SituationGroupRider'

  export default {
    name: 'situation-group',

    setup() {
      const startlist = useStartlistStore()
      const update = useUpdateStore()
      const config = useConfigStore()
      const pages = usePagesStore()

      return {
        startlist,
        update,
        config,
        pages
      }
    },

    props: {
      id: Number,
      group: Object
    },

    components: {
      SituationGroupRider
    },

    computed: {
      datosStartlist() {
        return this.multiFilter(this.startlist.riders, { dor: this.group.riders})
      },

      color1() {
        if(this.config.getConfig().general.fore_color != "#f0f0f0") {
          return {
            "color":  `${this.config.getConfig().general.fore_color}`,
          }
        } else {
          return {
            "color":  `${this.config.getConfig().general.color1}`
          }
        }
      },
    },

    methods: {
      aMinSegundos(milisegundos) {
        const value = milisegundos / 1000;
        const sec = parseInt(value, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        return '+ ' + minutes + ':' + seconds;
      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      kmsAMeta() {
        var kms_a_meta = (this.config.getConfig().general.distance - this.update.getSituation()[0].km).toFixed(1)

        if(kms_a_meta >= 0)
          return kms_a_meta
        else
          return 0
      },

      nombreGrupo(id) {
        if(id < 2)
          return this.$t('group_names[' + parseInt(id) + ']')
        else {
          return this.$t('group_names[2]')
          //return this.$t('group_names[2]') + " " + (parseInt(id) - 2)
        }
          
      },

      abrirPopup() {
        if(this.group.idg != 0) {
          this.pages.setPopupType('situationgroup')
          this.pages.setSelectedSituationGroup(this.group.idg)

          if(this.isMobile())
            this.pages.setVisiblePopup(true)
        }
      },
      
      isMobile() {
        return screen.width < 768
      },
    }

  }
</script>

<style scoped>

  @media (max-width: 768px) {
    .container_grupo {
      margin-bottom:10px;
    }

    .subgrupo {
      max-height:initial !important;
    }
  }

  .container_grupo {
    display:flex;
    margin-bottom:10px;
    position:relative;
    cursor: pointer;
    background: #fafafa;
  }

  .container_grupo:hover {
    background: white;
  }

  .container_grupo:last-child {
    margin-bottom: 0;
  }

  
  .small {
    font-weight: bold;
    color:#00446f;
    font-size: 12px;
    white-space: nowrap;
  }

  .big {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .big_img img {
    height: 13px;
    margin-right: 5px;
  }

  .grupo {
    width: 100%;
    border:1px solid #dbdbdb;
    padding: 10px;
    align-items:center;
    display:flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
  }

  .subgrupo {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .subgrupo:first-child {
    margin-bottom:5px;
  }

  .unknown {
    font-size: 14px;
  }

  .numero_corredores {
    position:absolute;
    top: 5px;
    right: 5px;
    background: #CCC;
    color: white;
    width: 15px;
    font-size:11px;
    text-align: center;
  }


</style>