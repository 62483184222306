<template>
  <div>
    <div class="titulo">
      <div class="km" v-if="item.km > 0 && item.km <= parseFloat(this.config.getConfig().general.distance)"><img :src="require(`@/assets/images/finish.png`)" class="icon">{{ kmToGo(item.km) }}km</div>
      <div class="fecha">{{ formattedDate(item.hor) }}</div>
    </div>
    <div class="texto" v-html="getTextoAudio()"></div>
    <div class="container_wave">
      <div class="button_container">  
        <div class="button" v-if="!playing" ref="play" @click="play"><svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m5 21v-18l14 9z" stroke="#999999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg></div>
        <div class="button" v-if="playing"  ref="pause" @click="pause"><svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke="#999999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m6 4h4v16h-4z"/><path d="m14 4h4v16h-4z"/></g></svg></div>
        <div class="button" ref="stop" @click="stop"><svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><rect height="16" rx="2" stroke="#999999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="16" x="4" y="4"/></svg></div>
      </div>
      <div class="wave_container">
        <vue-wave-surfer :ref="referencia()" :src="getURLAudio()" :options="options"></vue-wave-surfer>      
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { useConfigStore } from '@/store/config'
  import VueWaveSurfer from 'vue-wave-surfer/src/VueWaveSurfer'  
  
  export default {
    name: 'commentary-audio',

    data() {
      return {
        playing: false,        
        options: {
          waveColor: '#dcdcdc',
          cursorColor: '#dcdcdc',
          cursorWidth: 0,
          height: 50,
          progressColor: this.config.getConfig().general.color1,
          responsive: true,
        },
      }
    },

    setup() {
      const config = useConfigStore()

      return {
        config,
      }
    },

    props: {
      item: Object
    },

    components: {
      VueWaveSurfer
    },

    computed: {
    },

    methods: {

      formattedDate(timestamp) {
        return moment(parseInt(timestamp)).format('HH:mm\\h')
      },

      referencia() {
        return "wave-" + this.item.idm
      },
      
      player() {
        return this.$refs[this.referencia()].waveSurfer
      },

      play() {
        this.$refs[this.referencia()].waveSurfer.play()
        this.playing = true
      },

      pause() {
        this.$refs[this.referencia()].waveSurfer.pause()
        this.playing = false
      },

      stop() {
        this.$refs[this.referencia()].waveSurfer.stop()        
        this.playing = false
      },

      iconData(name) {
        switch (name) {
          case "km":
            return '<svg fill="none" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#BBB" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m6.0325 15.2873 5.9676 6.7127 5.9676-6.7127c4.577-5.1485.9217-13.2873-5.9676-13.2873-6.88931 0-10.54461 8.1388-5.9676 13.2873z"/><circle cx="12" cy="10" r="3"/></g></svg>'
          
          case "fecha":
            return '<svg fill="none" height="12" viewBox="0 0 24 24" width="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#BBB" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6v6l4 4"/></g></svg>'
          
          default:
            break;
        }
      },

      getURLiFrame() {
        return "https://tracker.helmuga.cloud/embed_audio.php?file=" + this.getURLAudio() + "ts=" + Math.random()
      },

      getURLAudio() {
        var commentary = this.item.tex[this.$i18n.locale]

        let div = document.createElement('div');
        div.innerHTML = commentary;
        var audioURL = div.getElementsByClassName('audio_commentary')

        return audioURL[0].innerHTML
      },
      
      getTextoAudio() {
        var commentary = this.item.tex[this.$i18n.locale]

        let div = document.createElement('div');
        div.innerHTML = commentary;
        var audioURL = div.getElementsByClassName('text_commentary')

        return audioURL[0].innerHTML
      },

      kmToGo(km_covered) {
        return parseFloat(this.config.getConfig().general.distance - km_covered).toFixed(1)
      }
    }
  }
  

</script>

<style scoped>
  @media (max-width: 768px) {
    .fecha {
      padding-right: 10px !important;
    }

    .km {
      padding-left: 10px !important;
    }

    .container_wave {
      padding: 0 10px !important;
    }

    .texto {
      padding: 0 10px !important;
      width: calc(100% - 20px) !important;
    }

  }

  .container_wave {
    display: flex;
  }

  .titulo {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .km {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #AAA;
  }

  .fecha {
    color: #CCC;
    font-size: 12px;
    margin-left: auto
  }

  .texto {
    text-align: left;
    font-size: 12px;
    width: 100%;
    margin-bottom: 5px;
  }

  .button_container {
    display: flex;
    justify-content: flex-start;
    width: 60px;
    align-items: center;
  }

  .wave_container {
    width: calc(100% - 60px);
  }

  .button {
    cursor: pointer;
  }

  .icon {
    height: 10px;
    margin-right: 3px;
    opacity: 0.2;
  }

  .wave {
    width: 100%;
  }

</style>
