<template>  
  <div class="container" @click="abrirPopup(final.idh)">
    <div class='km'>{{ final.km }}<br><span>km</span></div>
    <div class='imagenhito'><img :src="imagenhito()"></div>
    <div class='textohito'>{{ final.des }}</div>
    <div class='hour'>{{ getTime(final.km) }}</div>
  </div>
</template>

<script>
  import { useTimetableStore } from '@/store/timetable'
  import { usePagesStore } from '@/store/pages'
  import { useUpdateStore } from '@/store/update'  
  import moment from 'moment'  

  export default {
    name: 'racetimetableitem',

    props: {
      key: Number,
      final: Object,
    },

    setup() {
      const pages = usePagesStore()
      const timetable = useTimetableStore()
      const update = useUpdateStore()

      return {
        pages,
        timetable,
        update
      }
    },

    methods: {
      
      imagenhito() {

        switch(this.final.hit) {

          case "start":
          case "finish":
            return require(`@/assets/images/${this.final.hit.toLowerCase()}.png`)

          default:
            return require(`@/assets/images/profile_${this.final.hit}_${this.final.cat.toLowerCase()}.png`)
        }
          
      },

      getTime(km) {

        if(km == 0) {
          return this.hora(this.update.getGeneral().started)
        }

        let filtro = this.timetable.getTimetable().data.filter((item) => item.km == Math.floor(km))

        if(filtro.length > 0 && filtro[0].hor1 != "" && filtro[0].hor1 != 0)
          return this.hora(filtro[0].hor1 * 1000 + this.update.getGeneral().started)
        else
          return ""
      },

      async abrirPopup(idhorario) {

        this.pages.data.selectedTimetablePoint = idhorario

        this.pages.setPopupType('')
        await new Promise(r => setTimeout(r, 100))
        this.pages.setPopupType('timetablepointinfo')

        if(this.isMobile())
          this.pages.setVisiblePopup(true)
      },
      
      hora: function (date) {
        moment.locale(this.$i18n.locale)
        let formatString = 'HH:mm[' + this.$t('h') + ']'
        return moment(parseInt(date)).format(formatString);
      },
      
      isMobile() {
        return screen.width < 768
      },


    }

  }
</script>

<style scoped>

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 40px auto 50px;
    padding: 12px 10px;
    font-size: 12px;
    width: calc(100% - 22px);
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    background: white;
    cursor: pointer;
  }

  .container:hover {
    background: #fafafa;
  }

  .imagenhito {
    text-align: center;
  }

  .imagenhito img {
    width: 20px;
    height: 20px;
  }

  .textohito {
    text-transform: uppercase;
  }

  .km {
    font-weight: bold;
    text-align: center;
  }

  .km > span {
    font-weight: initial;
    color: #AAA;
  }

  .hour {
    text-align: right;
    color: #AAA;
  }


</style>