<template>
  <div>

    <div v-if="hayResultadosVirtuales() || hayResultadosFinales() || hayResultadosLiveTiming() || this.results.getFilterClassification() == '-9999'" class="container_virtual">   
      <div class="title" v-if="this.startedRace() && !hayResultadosFinales() && this.results.getFilterClassification() >= 0">
        <div style="display:flex;">
          <div class="img_category_container" v-if="getTipo().thumb"><img class='img_category' :src="getTipo().thumb"></div>
          <div>
            {{ getNombreClasificacion() }}
            <div class="subtitle">{{ $t('virtual_classification') }}</div>
          </div>
        </div>
        <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
      </div>

      <div class="title" v-if="this.startedRace() && hayResultadosFinales() && this.results.getFilterClassification() >= 0">
        <div style="display:flex;">
          <div class="img_category_container" v-if="getTipo().thumb"><img class='img_category' :src="getTipo().thumb"></div>
          <div>
            {{ getNombreClasificacion() }}
            <div class="subtitle">{{ $t('final_classification') }} - 
              <span v-if="getTextOfficial() == '0'">{{ $t('provisional') }}</span>
              <span v-if="getTextOfficial() == '1'">{{ $t('official') }}</span>
            </div>
          </div>
        </div>
        <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
      </div>

      <div class="title" v-if="!this.startedRace() && this.results.getFilterClassification() >= 0">
        <div style="display:flex;">
          <div class="img_category_container" v-if="getTipo().thumb"><img class='img_category' :src="getTipo().thumb"></div>
          <div>
            {{ getNombreClasificacion() }}
            <div class="subtitle">{{ $t('start_classification') }}</div>
          </div>
        </div>
        <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
      </div>

      <div v-if="this.config.getConfig().general.type !== 'laps' && (typeof this.config.getConfig().general.flags === 'undefined' || this.config.getConfig().general.flags == 0)">
        <div class="title" v-if="this.results.getFilterClassification() < 0 && this.results.getFilterClassification() > -9999">
          <div style="display:flex;">
            <div v-if="getData().lap != '0'" class='laps'><span class='laps_text'>{{ $t('lap') }}</span><span class='laps_number'>{{ getData().lap }}</span></div>
            <div v-if="getData().lap == '0' && getTipo().tip == '-1'" class='laps' style="padding:0"><img class='img_category_time' :src="require(`@/assets/images/finish.png`)"></div>        
            <div v-if="getData().lap == '0' && getTipo().tip < -1 && getTipo().thumb == ''" class='laps' style="padding:0"><img class='img_category_time' :src="imageIntermedio(getTipo().num)"></div>
            <div v-if="getData().lap == '0' && getTipo().tip < -1 && getTipo().thumb != ''" class='laps' style="padding:0"><img class='img_category_time' :src="getTipo().thumb"></div>
            <div class="container_classification_name">
              <div>{{ getNombreClasificacion() }}</div>
              <div class="subtitle">{{ $t('livetiming_classification') }}</div>
            </div>
          </div>
          <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
        </div>
      </div>

      <div v-if="this.config.getConfig().general.type === 'laps' && (typeof this.config.getConfig().general.flags === 'undefined' || this.config.getConfig().general.flags == 0)">
        <div class="title" v-if="this.results.getFilterClassification() < 0 && this.results.getFilterClassification() > -9999">
          <div style="display:flex;">
            <div class="container_classification_name">
              <div>{{ this.getTipoNombreLaps().cat }}</div>
              <div class="subtitle">{{ $t('livetiming_classification') }}</div>
            </div>
          </div>
          <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
        </div>
      </div>

      <div v-if="typeof this.config.getConfig().general.flags !== 'undefined' && this.config.getConfig().general.flags == 1">
        <div class="title" v-if="this.results.getFilterClassification() < 0 && this.results.getFilterClassification() > -9999">
          <div style="display:flex;">
            <div v-if="getData().lap == '0' && getTipo().tip == '-1'" class='laps' style="padding:0"><img class='img_category_time' :src="require(`@/assets/images/finish.png`)"></div>    
            <div class="container_classification_name">
              <div>{{ this.getTipoNombre() }}</div>
              <div class="subtitle">{{ $t('livetiming_classification') }}</div>
            </div>
          </div>
          <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
        </div>
      </div>

      <div class="title" v-if="this.results.getFilterClassification() == -9999 && this.config.getConfig().general.starttimes.length > 0">
        <div style="display:flex;align-items: center;">
          <div style="height:20px;"><img class='img_category_time' style="padding: 0 10px 0 0;" :src="require(`@/assets/images/start.png`)"></div>
          <div class="container_classification_name">
            <div>{{ getNombreClasificacion() }}</div>
            <div class="subtitle">{{ $t('first_rider' +  this.config.getGenere()) }} {{ formattedDate(this.config.getConfig().general.starttimes[0].tim) }} | {{ $t('last_rider' + this.config.getGenere()) }} {{ formattedDate(this.config.getConfig().general.starttimes[this.config.getConfig().general.starttimes.length - 1].tim) }}</div>
          </div>
        </div>
        <div class="title_logo"><img :src="this.getRaceThumbBackground()"></div>
      </div>

      <div class="title" style="border: 1px solid #dbdbdb;justify-content: center;padding: 20px;" v-if="this.results.getFilterClassification() == -9999 && this.config.getConfig().general.starttimes.length == 0">
        {{ $t('no_start_times_available') }}
      </div>

      <div class="list">
        <div v-if="this.results.getFilterClassification() >= 0">
          <div v-if="showLess">
            <ResultsFullItem
              v-for="(final, index) in getData().riders.slice(0, 10)"
              :key="index"
              :final="final"
            ></ResultsFullItem>
            <div v-if="getData().riders.length > 10" @click="showLess = false" class="show">{{ $t('show_more') }}</div>
          </div>
          <div v-else> 
            <ResultsFullItem
              v-for="(final, index) in getData().riders"
              :key="index"
              :final="final"
            ></ResultsFullItem>
            <div @click="showLess = true" class="show">{{ $t('show_less') }}</div>
          </div> 
        </div>

        <div v-if="this.results.getFilterClassification() < 0 && this.results.getFilterClassification() > -9999">
          <div v-if="getData().riders.length > 0">
            <div v-if="showLess">
              <ResultsFullItemLiveTiming
                v-for="(final, index) in getData().riders.slice(0, 10)"
                :key="index"
                :firsttime="getData().riders.slice(0, 10)[0]"
                :indice="index"
                :final="final"
              ></ResultsFullItemLiveTiming>
              <div v-if="getData().riders.length > 10" @click="showLess = false" class="show">{{ $t('show_more') }}</div>
            </div>
            <div v-else>
              <ResultsFullItemLiveTiming
                v-for="(final, index) in getData().riders"
                :key="index"
                :firsttime="getData().riders.slice(0, 10)[0]"
                :indice="index"
                :final="final"
              ></ResultsFullItemLiveTiming>
              <div @click="showLess = true" class="show">{{ $t('show_less') }}</div>
            </div>
          </div>
        </div>
        <div v-if="this.results.getFilterClassification() == -9999">
          <div class="show_top">
            <div class="number_container">
              <span class="number">{{ this.getStartedRiders().length }}</span>
              <span class="number_text">{{ $t('riders_started') }}</span>
            </div>            
            <div class="number_container">
              <span class="number">{{ this.getStartTimes().length }}</span>
              <span class="number_text">{{ $t('riders_not_started') }}</span>
            </div>
          </div>
          <div v-if="showLess">
            <ResultsFullItemStartTimes
              v-for="(final, index) in this.getStartTimes().slice(0, 10)"
              :key="index"
              :final="final"
            ></ResultsFullItemStartTimes>
            <div v-if="this.getStartTimes().length > 10" @click="showLess = false" class="show">{{ $t('show_more') }}</div>
          </div>
          <div v-else> 
            <ResultsFullItemStartTimes
              v-for="(final, index) in this.getStartTimes()"
              :key="index"
              :final="final"
            ></ResultsFullItemStartTimes>
            <div @click="showLess = true" class="show">{{ $t('show_less') }}</div>
          </div>
        </div>

      </div>
    </div>
    
    <div v-if="(!hayResultadosVirtuales() && !hayResultadosFinales() && !hayResultadosLiveTiming() && this.results.getFilterClassification() != -9999) || (getData().riders.length == 0 && this.results.getFilterClassification() != -9999)"  class="title" style="border: 1px solid #dbdbdb; background: #fff;justify-content: center;padding: 20px;border-radius:0px;">
      {{ $t('no_results_available') }}
    </div> 
  </div>
</template>

<script>
  import { useUpdateStore } from '@/store/update'
  import { useResultsStore } from '@/store/results'
  import { useConfigStore } from '@/store/config'
  import { useStartlistStore } from '@/store/startlist'
  import ResultsFullItem from '@/components/ResultsFullItem'
  import ResultsFullItemLiveTiming from '@/components/ResultsFullItemLiveTiming'
  import ResultsFullItemStartTimes from '@/components/ResultsFullItemStartTimes'
  import moment from 'moment'

  export default ({
    setup() {
      const update = useUpdateStore()
      const results = useResultsStore()
      const config = useConfigStore()
      const startlist = useStartlistStore()

      return {
        update,
        results,
        config,
        startlist
      }
    },

    data () {
      return { 
        showLess: true
      }
    },

    components: {
      ResultsFullItem,
      ResultsFullItemLiveTiming,
      ResultsFullItemStartTimes,
    },

    methods: {

      hayResultadosVirtuales() {
        var tipo_global = this.results.getFilterClassification()

        var filtradoPorTipo = this.update.getResultsVirtual().filter(function(item) {
          return item.tip == tipo_global
        })

        if(typeof filtradoPorTipo[0] !== "undefined" && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false
      },
      
      hayResultadosFinales() {

        var tipo_global = this.results.getFilterClassification()

        var filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
          return item.tip == tipo_global
        })

        if(typeof filtradoPorTipo[0] !== "undefined" && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false

      },

      hayResultadosLiveTiming() {
        
        var tipo_global = this.results.getFilterClassification()        

        var filtradoPorTipo = this.update.getResultsLiveTiming().filter(function(item) {
          return item.raceid == tipo_global
        })
        
        if(typeof filtradoPorTipo[0] !== "undefined" && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false

      },

      getData() {
        var tipo_global = this.results.getFilterClassification()
        var filtradoPorTipo
        const self = this

        switch (this.getTipoCarrera()) {

          case "itt":
          case "ttt":
          case "popular":
            if(tipo_global >= 0) {
              if(this.hayResultadosFinales()) {
                filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
                  return item.tip == tipo_global
                })
              } else {
                filtradoPorTipo = this.update.getResultsVirtual().filter(function(item) {
                  return item.tip == tipo_global
                })
              }
            } else {

              filtradoPorTipo = this.update.getResultsLiveTiming().filter(function(item) {
                return item.raceid == tipo_global
              })
            }
          break

          case "laps":
            filtradoPorTipo = this.update.getResultsLiveTiming().filter(function(item) {
              return item.raceid == tipo_global
            })
          break
        
          default:
            if(tipo_global >= 0) {
              if(this.startedRace()) {
                if(this.hayResultadosFinales()) {
                  filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
                    return item.tip == tipo_global
                  })
                }
                else {
                  filtradoPorTipo = this.update.getResultsVirtual().filter(function(item) {
                    return item.tip == tipo_global
                  })
                }
              } else {
                  filtradoPorTipo = this.update.getResultsStart().filter(function(item) {
                    return item.tip == tipo_global
                  })
              }
            } else {
              filtradoPorTipo = this.update.getResultsLiveTiming().filter(function(item) {
                return item.raceid == tipo_global
              })
            }

          break
        }

        if(typeof filtradoPorTipo[0] !== "undefined") {

          let opcionesFiltro = this.results.getFilterProperties()

          let copiaFiltradoPorTipo = JSON.parse(JSON.stringify(filtradoPorTipo));

          copiaFiltradoPorTipo.forEach(filtro => {
            Object.keys(opcionesFiltro).forEach(prop => {
                if (opcionesFiltro[prop].length > 0) {
                    filtro.riders = filtro.riders.filter(rider => opcionesFiltro[prop].includes(rider[prop]));
                }
            });
          })

          return copiaFiltradoPorTipo[0]
        }
        else
        {
          return {riders:[]}
        }
      },

      getTipo() {
        var tipo_global = this.results.getFilterClassification()

        var item_seleccionado = this.config.getConfig().classifications.filter(function(item) {
          if(item.tip == tipo_global)
            return item
        })

        return item_seleccionado[0]
      },

      getTipoNombre() {
        var tipo_global = this.results.getFilterClassification()
        let selectedCategory = this.results.getFilterProperties()['cat']

        if(typeof selectedCategory === 'undefined')
          return

        var item_seleccionado = this.config.getConfig().classifications.filter(function(item) {
          if(item.tip == tipo_global && item.cat == selectedCategory)
            return item
        })

        if(item_seleccionado.length == 0)
          return

        if(item_seleccionado[0].tip == -1)
          return this.$t('ranking_-1')
        else
          return item_seleccionado[0].dis + "m"
      },

      getTipoNombreLaps() {
        var tipo_global = this.results.getFilterClassification()

        var item_seleccionado = this.config.getConfig().classifications.filter(function(item) {
          if(item.tip == tipo_global)
            return item
        })

        return item_seleccionado[0]
      },

      getTipoCarrera() {
        return this.config.getConfig().general.type
      },

      getNombreClasificacion() {
        if(typeof this.getTipo().langs[this.$i18n.locale] !== 'undefined')
          return this.getTipo().langs[this.$i18n.locale]
        else
          return this.$t('ranking_' + this.getTipo().tip)
      },

      getTextOfficial() {
        return this.getData().off
      },

      formattedDate(timestamp) {
        return moment(parseInt(timestamp)).format('HH:mm');
      },

      imageIntermedio(num) {
        //return "https://tracker.helmuga.cloud/images/time_" + num + ".png";
        return require(`@/assets/images/time_${num}.png`)
      },

      aMinSegundos(milisegundos) {
        const value = milisegundos / 1000;
        const sec = parseInt(value, 10); 
        let hours   = Math.floor(sec / 3600); 
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60); 
        
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        
        return '+ ' + minutes + ':' + seconds;
      },

      startedRace() {
        return this.update.getUpdate().situation[0].km > 0 || this.update.getResultsLiveTiming().length > 0
      },      

      getRaceThumbBackground() {
        return this.config.getConfig().general.race_thumb_background
      },

      getStartTimes() {
        var now = Date.now()
        return this.config.getConfig().general.starttimes.filter(item => item.tim > now)
      },

      getStartedRiders() {
        var now = Date.now()
        return this.config.getConfig().general.starttimes.filter(item => item.tim <= now)
      }

    }

    
  })

</script>

<style scoped>

  .container_virtual {
    display: flex;
    flex-direction: column;
  }

  .container_classification_name {
    display:flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    background: #fafafa;
    border-top: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
   
  .title_logo {
    height: 40px;
    opacity: 0.5;
  } 

  .title_logo img {
    height: 40px;
  }
  
  .show {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    text-transform: uppercase;
    font-size: 12px;
    justify-content: center;
    background: #fafafa;
    color: #999;
    border-bottom: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    cursor: pointer;
    transition: 0.3s;
  }

  .show:hover {
    background: #FFF;
  }
  
  .show_top {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 10px;
    font-size: 12px;
    color: #999;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    transition: 0.3s;
  }

  .show:hover {
    background: #FFF;
  }

  .number_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .number {
    font-size: 25px;
    color: #333;
  }

  .subtitle {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }

  .img_category {
    height: 30px;
    padding-right: 10px;
    display: flex;
  }

  .img_category_time {
    height: 20px;
    padding: 10px 10px 10px 0px;
  }

  .laps {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .laps_number {
    font-size: 16px;
  }

  .laps_text {
    font-size: 11px;
    font-weight: normal;
  }

  .img_category_container {
    display: flex;
    align-items: center;
  }

</style>