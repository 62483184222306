<template>

  <div class="container" v-if="final.nom != ''">
      <div class="lap" v-if="this.results.getFilterClassification() < -10 && this.results.getFilterClassification() != -9999">{{ final.lap }}</div>
      <div class="lap" v-if="this.results.getFilterClassification() > -10 || this.results.getFilterClassification() == -9999" style="height:15px;"><img class="thumb_icon" :src="imageIntermedio(final.cla)"></div>
      <div class="position">{{ final.pos }}</div>  
      <div class="container_name">
        <span class="name">{{ final.nom }}</span>
      </div>
      <div class="result">
        <div>{{ getResult() }}</div>
        <div class="diff red" v-if="final.dif.startsWith('+')">{{ final.dif }}</div>
        <div class="diff green" v-if="final.dif.startsWith('-')">{{ final.dif }}</div>
    </div>
  </div>

  <div class="container" v-if="final.nom == '' && ['itt', 'popular'].includes(this.config.getConfig().general.type)">
    <div class="lap" v-if="this.results.getFilterClassification() < -10 && this.results.getFilterClassification() != -9999">{{ $t('lap') }} {{ final.lap }}</div>
    <div class="lap" v-if="this.results.getFilterClassification() > -10 || this.results.getFilterClassification() == -9999" style="height:15px;"><img class="thumb_icon" :src="imageIntermedio(final.cla)"></div>
    <div class="position">{{ final.pos }}</div>
    <div class="container_name">
      <div class="name">{{ datosRiderFiltrado.nfu }} <span class="surname">{{ datosRiderFiltrado.afu }}</span></div>
    </div>
    <div class="result">
      <div>{{ getResult() }}</div>
      <div class="diff red" v-if="final.dif.startsWith('+')">{{ final.dif }}</div>
      <div class="diff green" v-if="final.dif.startsWith('-')">{{ final.dif }}</div>
    </div>
    
  </div>

  <div class="container" v-if="final.nom == '' && ['laps'].includes(this.config.getConfig().general.type)">
    <div class="lap" ><span class="current_lap">{{ final.lap }}</span></div>
    <div class="position">{{ final.pos }}</div>
    <div class="container_name">
      <div class="name">{{ datosRiderFiltrado.nfu }} <span class="surname">{{ datosRiderFiltrado.afu }}</span></div>
    </div>
    <div class="result">
      <div>{{ getResult() }}</div>
      <div class="diff red" v-if="final.dif.startsWith('+')">{{ final.dif }}</div>
      <div class="diff green" v-if="final.dif.startsWith('-')">{{ final.dif }}</div>
    </div>
    
  </div>

  <div class="container" v-if="final.nom == '' && this.config.getConfig().general.type == 'ttt'">
    <div class="lap" v-if="this.results.getFilterClassification() < -10 && this.results.getFilterClassification() != -9999">{{ $t('lap') }} {{ final.lap }}</div>
    <div class="lap" v-if="this.results.getFilterClassification() > -10 || this.results.getFilterClassification() == -9999" style="height:15px;"><img class="thumb_icon" :src="imageIntermedio(final.cla)"></div>
    <div class="position">{{ final.pos }}</div>
    <div class="container_name">
      <div class="name"><span class="surname">{{ datosRiderFiltrado.nom }}</span></div>
    </div>
    <div class="result">
      <div>{{ getResult() }}</div>
      <div class="diff red" v-if="final.dif.startsWith('+')">{{ final.dif }}</div>
      <div class="diff green" v-if="final.dif.startsWith('-')">{{ final.dif }}</div>
    </div>
  </div>


</template>

<script>

  import { useStartlistStore } from '@/store/startlist'
  import { useResultsStore } from '@/store/results'
  import { useConfigStore } from '@/store/config'
  import { useUpdateStore } from '@/store/update'

  export default {
    name: 'resultsfull-livetiminglog',

    props: {
      key: Number,
      final: Object,
    },

    setup() {
      const startlist = useStartlistStore()
      const results = useResultsStore()
      const config = useConfigStore()
      const update = useUpdateStore()
      

      return {
        startlist,
        results,
        config,
        update
      }
    },

    computed: {      
      datosRiderFiltrado() {
        if(this.config.getConfig().general.type == 'ttt')
          return this.datosTeam(this.final.dor)
        else
          return this.datosRider(this.final.dor)
      }
    },

    created() {
      this.filterClassificationMenus()
    },

    methods: {

      datosRider(dorsal) {
        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.riders.filter(function(item) {
          return item.dor == dorsal
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0]
        else
          return ""

      },

      datosTeam(dorsal) {
        var filtradoPorTipo = null
        filtradoPorTipo = this.startlist.teams.filter(function(item) {
          return item.num == dorsal
        })

        if(typeof filtradoPorTipo[0] !== "undefined")
          return filtradoPorTipo[0]
        else
          return ""

      },

      multiFilter(array, filters) {
        let filterKeys = Object.keys(filters);
        return array.filter((item) => filterKeys.every((key) => (filters[key].indexOf(item[key]) !== -1)));
      },

      formatPositionVariation(position) {
        if(position > 0)
          return `<span style='color:green;'>(+${position})</span>`
        else
          return `<span style='color:red;'>(${position})</span>`
      },
      
      sinPositionVariation() {
        return this.final.pos_ofi == undefined
      },
      
      conPositionVariation() {
        return this.final.pos_ofi != undefined
      },

      getResult() {
        var res = this.final.res

        if(res.startsWith('**')) {
          return res.replace('**', '') + " " + this.$t('laps')
        } else {
          return res
        }
          
      },

      imageIntermedio(num) {
        var filtradoPorTipo = this.filterClassificationMenus().filter(function(item) {
          return item.tip == -num
        })

        num = filtradoPorTipo[0].num
        var idclasification = filtradoPorTipo[0].tip
        
        if(idclasification == -1)
          return require(`@/assets/images/finish.png`);
        else
          return require(`@/assets/images/time_${num}.png`);
      },

      filterClassificationMenus() { 

        var arrayMenus = []
        var arrayReturn = []
        var arrayReturnResults = []
        var arrayReturnLiveTiming = []
        var i = 0
        
        this.config.getClassifications().forEach(element => {
            if(element.tip < 0)
              arrayReturnLiveTiming.push(element)
        })

        
        this.update.getResultsFinal().forEach(element => {
          if(((element.tip == "0") && element.riders.length > 0) || (element.tip != "0"))
          {
            arrayMenus.push(element.tip)
          }
        })

        arrayReturnResults = this.multiFilter(this.config.getClassifications(), { tip: arrayMenus})
        arrayReturn = arrayReturnLiveTiming.concat(arrayReturnResults)

        if(arrayReturn.length > 0 && this.results.getFilterClassification() == "") {
          this.results.setFilterClassification(arrayReturn[0].tip)
        }

        return arrayReturnLiveTiming.concat(arrayReturnResults)

      },
    }


  }

</script>

<style scoped>

  @media (max-width: 900px) {

    .container {
      grid-template-columns: 0.15fr 0.15fr 1fr 0.2fr !important;
    }

    .country {
      display: none !important;
    }

  }

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 0.1fr 0.2fr 1fr 0.4fr;
    padding: 12px 10px;
    font-size: 14px;
    width: calc(100% - 22px); 
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    background: white;
  }

  .container:first-child {
    border-top: 1px solid #dbdbdb;
  }
  
  .lap {
    display: flex;
    justify-content: center;
  }

  .position {
    text-align: center;
  }

  .position_variation {
    text-align: center;
    font-size: 10px;
  }

  .bib {
    text-align: center;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#777;
  }

  .container_thumb {
    text-align: center;
  }
  
  .thumb {
    height:35px;
    border-radius: 35px;
    margin: 0 10px;
  }

  .country{
    height:13px;
    padding: 0 10px;
  }

  .container_name {
    display: flex;
    align-items: center;
  }

  .name {
    text-align: left;
  }

  .surname {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .team {
    text-align: right;
    color: #777;
    font-size: 11px;
  }

  .result {
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
  }
  
  .diff {
    margin-left: 5px;
    font-size: 12px;
  }

  .red {
    color: rgb(252, 68, 68);
  }

  .green {
    color: green;
  }

  .thumb_icon {
    height: 15px;
  }

  .current_lap {
    width: 18px; /* Ajusta el tamaño según lo necesites */
    height: 18px; /* Debe ser el mismo valor que el width para que sea un círculo */
    background-color: #337ACF;
    border-radius: 50%; /* Hace que el div sea un círculo */
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
    color: white;
    font-size: 10px; /* Tamaño del número */
    font-weight: bold; /* Hace que el número sea negrita */
  }
</style>