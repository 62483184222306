<template>
  <div class="contenedor_layoutdashboard">
    <Map class="container" v-if="pages.getFullScreenMap() && this.map.getProfileData().length > 0" ref="mapa" />

    <div class="container" v-if="!pages.getFullScreenMap()">
      <div class="container_type" v-if="this.config.getConfig().general.type == 'road' || this.config.getConfig().general.type == 'triathlon'">
        <div class="situation" :style="ordenPaneles(0)">
          <RaceTitle class="racetitle"></RaceTitle>
          <div v-if="this.pages.getPageVisible() === 'dashboard' && this.pages.getPopupType() !== 'race' && this.pages.getPopupType() !== 'riders' && this.pages.getPopupType() !== 'situationgroup' && this.pages.getPopupType() !== 'timetablepointinfo'">
            <SituationMain />
          </div>
          <Transition name="slide-fade">
            <div v-if="!isMobile() && this.pages.getPageVisible() === 'dashboard' && this.pages.getPopupType() === 'race'" style="position:relative;">
              <div class='close' @click="closePanel()">×</div>
              <RaceInfo />
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="!isMobile() && this.pages.getPageVisible() === 'dashboard' && this.pages.getPopupType() === 'riders' && this.config.getConfig().general.fcintegration === '0'" style="position:relative;">
              <div class='close' @click="closePanel()">×</div>
              <StartlistRiderInfo />
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="!isMobile() && this.pages.getPageVisible() === 'dashboard' && this.pages.getPopupType() === 'riders' && this.config.getConfig().general.fcintegration === '1'" style="position:relative;">
              <div class='close' @click="closePanel()">×</div>
              <LayoutIframe />
            </div>
          </Transition>
          <Transition name="slide-fade">
            <div v-if="!isMobile() && this.pages.getPageVisible() === 'dashboard' && this.pages.getPopupType() === 'situationgroup'" style="position:relative;">
              <div class='close' @click="closePanel()">×</div>
              <SituationGroupInfo />
            </div>
          </Transition>        
          <Transition name="slide-fade">
            <div v-if="!isMobile() && this.pages.getPageVisible() === 'dashboard' && this.pages.getPopupType() === 'timetablepointinfo'" style="position:relative;">
              <div class='close' @click="closePanel()">×</div>
              <TimetablePointInfo />
            </div>
          </Transition>
        </div>
        
        <div v-if="this.isMobile()">
          <div class="content" :style="ordenPaneles(2)">
            <div class="separator">{{ $t('profile') }}</div>
            <Profile 
              v-if="this.map.getProfileData().length > 0"
              ref="perfil"
              class="perfil"
            ></Profile>
            <div v-if="this.config.getConfig().general.youtube" class="separator">{{ $t('video') }}</div>
            <Youtube v-if="this.config.getConfig().general.youtube" class="video" :idYoutube="this.config.getConfig().general.youtube" />
            <div class="separator">{{ $t('map') }}</div>
            <div class="mapa" v-if="this.config.getConfig().general.youtube"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
            <div class="mapa" v-if="this.config.getConfig().general.youtube == ''" style="height:calc(100% - 200px);"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
          </div>
          <div class="commentary_container" :style="ordenPaneles(1)" v-show="this.getCommentaries().length > 0">
            <div class="separator">{{ $t('commentaries') }}</div>
            
            <div v-show="this.config.getConfig().general.hidecommentaries == '0'">
              <div class="commentary" v-show="this.startlist.commentariesVisibleDashboard || isMobile()">
                <Commentary ref="commentary"></Commentary>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!this.isMobile()" class="container_pc">        
          <Profile 
            v-if="this.map.getProfileData().length > 0"
            ref="perfil"
            class="perfil"
          ></Profile>

          <div style="width:100%;height:calc(100% - 220px);display: flex;">
            <div class="content" :style="ordenPaneles(2)">
              <div class="separator">{{ $t('profile') }}</div>
              <div v-if="this.config.getConfig().general.youtube" class="separator">{{ $t('video') }}</div>
              <Youtube v-if="this.config.getConfig().general.youtube" class="video" :idYoutube="this.config.getConfig().general.youtube" />
              <div class="separator">{{ $t('map') }}</div>
              <div class="mapa" v-if="this.config.getConfig().general.youtube"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
              <div class="mapa" v-if="this.config.getConfig().general.youtube == ''" style="height:100%;"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
            </div>
            <div class="commentary_container" :style="ordenPaneles(1)" v-show="this.getCommentaries().length > 0">
              <div class="separator">{{ $t('commentaries') }}</div>
              
              <div v-show="this.config.getConfig().general.hidecommentaries == '0'">
                <div class="commentary">
                  <Commentary ref="commentary"></Commentary>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="container_type" v-if="this.config.getConfig().general.type == 'itt' || this.config.getConfig().general.type == 'ttt'">
        <div class="situation" :style="ordenPaneles(0)">
          <RaceTitle class="racetitle"></RaceTitle>
          <div class="separator">{{ $t('profile') }}</div>
          <Profile 
            v-if="this.map.getProfileData().length > 0"
            ref="perfil"
            class="perfil"
          ></Profile>
          <div class="separator">{{ $t('map') }}</div>
          <div class="mapa" style="margin:0;width:100%;"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
        </div>
        <div class="content_itt" :style="ordenPaneles(2)">
          <div class="menu"><ResultsMenuLiveTiming /></div>
          <ResultsFull class="results_full"></ResultsFull>
        </div>
        <div class="commentary_container_itt" :style="ordenPaneles(1)" v-show="this.getCommentaries().length > 0">
          <div class="separator">{{ $t('commentaries') }}</div>
          <div class="commentary" v-show="this.startlist.commentariesVisibleDashboard || isMobile()">
            <Commentary ref="commentary"></Commentary>
          </div>
        </div>
      </div>
      
      <div class="container_type" v-if="this.config.getConfig().general.type == 'popular'">
        <div class="situation_popular" :style="ordenPaneles(0)">
          <RaceTitle class="racetitle"></RaceTitle>
          <div class="separator">{{ $t('profile') }}</div>
          <Profile 
            v-if="this.map.getProfileData().length > 0"
            ref="perfil"
            class="perfil"
          ></Profile>
          <div class="separator">{{ $t('map') }}</div>
          <div class="mapa" style="margin:0;width:100%;flex-grow: 1;"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
        </div>
        <div class="commentary_container_itt" :style="ordenPaneles(3)" v-show="this.getCommentaries().length > 0">
          <div class="separator">{{ $t('commentaries') }}</div>
          <div class="commentary" v-show="this.startlist.commentariesVisibleDashboard || isMobile()">
            <Commentary ref="commentary"></Commentary>
          </div>
        </div>
      </div>
      
      <div class="container_type" v-if="this.config.getConfig().general.type == 'laps'">
        <div class="situation_popular" :style="ordenPaneles(0)">
          <RaceTitle class="racetitle"></RaceTitle>
          <div class="separator">{{ $t('profile') }}</div>
          <Profile 
            v-if="this.map.getProfileData().length > 0"
            ref="perfil"
            class="perfil"
          ></Profile>
          <div class="separator">{{ $t('map') }}</div>
          <div class="mapa" style="margin:0;width:100%;flex-grow: 1;"><Map v-if="this.map.getProfileData().length > 0" ref="mapa" /></div>
        </div>
        <div class="commentary_container_itt" :style="ordenPaneles(3)" v-show="this.getCommentaries().length > 0">
          <div class="separator">{{ $t('commentaries') }}</div>
          <div class="commentary" v-show="this.startlist.commentariesVisibleDashboard || isMobile()">
            <Commentary ref="commentary"></Commentary>
          </div>
        </div>
      </div>
    </div>
  
  </div>  
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import SituationMain from '@/components/SituationMain.vue'
  import Situation from '@/components/Situation.vue'
  import SituationNotStarted from '@/components/SituationNotStarted.vue'  
  import SituationFinished from '@/components/SituationFinished.vue'  
  import SituationCancelled from '@/components/SituationCancelled.vue'  
  import TimetablePointInfo from '@/components/TimetablePointInfo.vue'  
  import RaceTitle from '@/components/RaceTitle'
  import Commentary from '@/components/Commentary.vue'
  import Youtube from '@/components/Youtube.vue'
  import { useMapStore } from '@/store/map'
  import { useUpdateStore } from '@/store/update'
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import { useStartlistStore } from '@/store/startlist'
  import ResultsMenuLiveTiming from '@/components/ResultsMenuLiveTiming.vue'
  import ResultsFull from '@/components/ResultsFull.vue'
  import StartlistRiderInfo from '@/components/StartListRiderInfo.vue'
  import Profile from '@/components/Profile.vue'
  import Map from '@/components/Map.vue'
  import RaceInfo from '@/components/RaceInfo'
  import SituationGroupInfo from '@/components/SituationGroupInfo'
  import LayoutIframe from '@/layouts/LayoutIframe.vue'

  export default {
    name: 'LayoutDashboard',

    props: ['testProp'],

    setup() {

      const map = useMapStore()
      const update = useUpdateStore()
      const config = useConfigStore()
      const pages = usePagesStore()
      const startlist = useStartlistStore()

      return {
        map,
        update,
        config,
        pages,
        startlist
      }
    },

    mounted() {
      //console.log(this.pages.getPopupType())

    },

    methods: {

      ordenPaneles(panel) {

        if(!this.isMobile())
          return ""

        switch(this.config.getConfig().general.type) {
          case "itt":
          case "ttt":
          case "popular":
          case "laps":
            var orden_itt = [0, 1, 2, 3]
            return { order: orden_itt[panel] }

          default:
            var orden_default = [1, 3, 2]
            return { order: orden_default[panel] }
        }

      }, 

      finishStageData() {

        var filtradoPorTipo = null

        filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
          return item.tip == 0
        })

        if(filtradoPorTipo.length > 0 && filtradoPorTipo[0].riders.length > 0)
          return true
        else
          return false

      },

      closePanel() {

        switch(this.pages.getPopupType()) {
          case "timetablepointinfo":
            this.pages.setPopupType('race')
          break

          default:
            this.pages.setPopupType('')
          break
        }
        
      },

      isMobile() {
        return screen.width < 768
      },

      initComponents() {
        //this.$refs.map3d.createMap3D()
      },

      updateComponents() {
        this.$refs.perfil.updatePerfil()
        this.$refs.mapa.updateMapa()
        this.$refs.commentary.refresh()
        //this.$refs.map3d.updateView()
      },

      onAfterLeave() {
        this.startlist.commentariesVisibleDashboard = true
      },
      
      getCommentaries() {
        return this.update.data.commentary.filter(item => item.tex && item.tex[this.$i18n.locale])
      }

    },

    components: {
      Commentary,
      SituationMain,
      /*Situation,
      SituationNotStarted,
      SituationFinished,
      SituationCancelled,*/
      RaceTitle,
      Youtube,
      ResultsMenuLiveTiming,
      ResultsFull,
      Profile,
      Map,
      RaceInfo,
      StartlistRiderInfo,
      SituationGroupInfo,
      TimetablePointInfo,
      LayoutIframe
      //StartlistRiderInfo: defineAsyncComponent(() => import('@/components/StartListRiderInfo.vue'))
      //Profile: defineAsyncComponent(() => import('@/components/Profile.vue')),
      //Map: defineAsyncComponent(() => import('@/components/Map.vue')),
      
    },

  }
</script>

<style scoped>

  @media (max-width: 768px) {

    .contenedor_layoutdashboard {
      flex-direction: column;
      width: calc(100% - 0px) !important;
    }

    .container_type {
      flex-direction: column-reverse;
      padding: 0px !important;
      width: calc(100% - 0px) !important;
    }

    .situation, .situation_popular {
      width: 100% !important;
      height:unset !important;
      margin-top: 0px !important;
      padding: 0px !important;
    }

    .commentary_container, .commentary_container_itt {
      width: 100% !important;
      height:unset !important;
      padding: 0 !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    .commentary {
      width: 100% !important;
    }

    .content, .content_itt {
      width: 100% !important;
      height:unset !important;
      padding: 0px !important;
    }    

    .mapa {
      width: 100% !important;
      height: 200px !important;
      margin: 0px!important;
    }

    .racetitle {
      display: none !important;
    }
    
    .separator {
      display: flex !important;
      width: calc(100% - 2px) !important;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 16px;
      margin-top: 5px;
      padding-top: 15px;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 15px;
      justify-content: center;
    }

    .video {
      height: 250px !important;
      width: 100% !important;
      margin: 0 !important;
    }

    .results_list, .results_full {
      width: calc(100% - 20px) !important;
      height: unset !important;
      overflow: unset !important;
      padding-right: 0px !important;
    }

    .results_full {
      margin-bottom: 0px !important;
      padding: 10px !important;
    }

    .menu {
      margin-top: 10px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;  
      margin-bottom: 0px !important;    
      padding-bottom: 10px !important;
      width: calc(100% - 20px) !important;
    }

  }

  .contenedor_layoutdashboard {
    width: 100%;
    display: flex;
  }
  
  .container {
    display: flex;
    width: 100%;
  }

  .container_type {
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
  }

  .container_pc {
    width:70%;
    display: flex;
    flex-direction: column;
  }

  .situation {
    width: 30%;
    height: 100%;
    overflow-y: auto;
    padding-right:10px;
  }

  .situation_popular {
    width: 60%;
    height: 100%;
    overflow-y: auto;
    padding-right:10px;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex: 1;
    height: 100%;
  }

  .iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }

  .commentary_container {
    width: 40%;
    height: calc(100% - 0px);
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .content_itt {
    width: 40%;
    height: 100%;
  }

  .commentary_container_itt{
    width: 30%;
    height: calc(100% - 0px);
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 1;
  }

  .commentary {
    width: 100%;

  }

  .perfil {
    width: 100%;
    height: 220px;
  }

  .mapa {
    width: calc(100% - 20px);
    height: calc(50% - 110px);
    background: #ddd;
    margin-right: 10px;
    margin-left: 10px;
  }

  .video {    
    width: calc(100% - 20px);
    height: calc(50% - 110px);
    background: #ddd;
    margin: 0 10px;
  }

  .racetitle {
    border: 1px solid #dbdbdb;
    padding: 10px;
    background: #fafafa;
    width: calc(100% - 22px);
  }

  .separator {
    display: none;
  }

  .menu {
    width: 100%;
    height: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .results_full {
    width: calc(100% - 10px);
    height: calc(100% - 70px);
    overflow-y: auto;
    padding-right: 10px;
  }

  .close {
    position: absolute;
    top: 1px;
    right: 0px;
    font-size: 40px;
    color: #CCC;
    cursor: pointer;
    background: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }

  /*.slide-fade-leave-active {
    transition: all 0.2s ease-out;
  }*/

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(60px);
    opacity: 0;
  }



</style>