<template>
  <div class="container_profile">  
     <div class="container_options">
       <div class="option" @click="zoomIn">
         <font-awesome-icon class="icono" :icon="['fas', 'plus']" />
       </div>  
       <div class="option" @click="zoomOut">
         <font-awesome-icon class="icono" :icon="['fas', 'minus']" />
       </div>  
       <div class="option" @click="togglePoints" v-if="this.hayDatosHorarios()">
         <font-awesome-icon class="icono" :icon="this.showPoints ? ['fas', 'clock'] : ['far', 'clock']" :style="{ color: this.showPoints ? '#DBDBDB' : '' }" />
       </div>
     </div>
     <div class="container_move_left" @click="moveChartLeft" v-if="this.showMoveLeft">
      <font-awesome-icon class="icono_move" :icon="['fas', 'arrow-left']" />
     </div>
     <div class="container_move_right" @click="moveChartRight" v-if="this.showMoveRight">
      <font-awesome-icon class="icono_move" :icon="['fas', 'arrow-right']" />
     </div>
     <apexchart
       ref="perfil"
       width="100%"
       height="100%"
       type="area"
       :options="chartOptions"
       :series="series"
       @mounted="onChartMounted" 
     ></apexchart>
   </div>
</template>

<script>
 import { useMapStore } from '@/store/map'
 import { useTimetableStore } from '@/store/timetable'
 import { useUpdateStore } from '@/store/update'
 import { useConfigStore } from '@/store/config'  
 import moment from 'moment'

 export default {
 name: "home",

 setup() {
   const map = useMapStore()
   const timetable = useTimetableStore()
   const update = useUpdateStore()
   const config = useConfigStore()

   return {
     map,
     timetable,
     update,
     config
   }
 },

 data() {
   return {

     showPoints: false,
     
     series: this.getSeries(),

     zoomLevel: 1, 
     zoomFactor: 0.2,
     initialRange: { min: null, max: null },
     chartReady: false,

     showMoveLeft: false,
     showMoveRight: false,

     shiftAmount: 10,

     chartOptions: {

       chart: {
         id: 'profile-chart',
         type: 'area',
         foreColor: '#AAA',

         toolbar: {
           show: false,
           autoSelected: 'pan',
           tools: {
             download: false,
             selection: false,
             zoom: false,
             pan: true
           }
         },

         zoom: {
            enabled: true,
            type: "x", // Habilitar el zoom solo en el eje x
            //autoScaleYaxis: true, // Ajusta el eje Y automáticamente al hacer zoom
          },


         events: {
           click: (event, chartContext, config) => {
             console.log(event.target);
           },
         },

         animations: {
           enabled: false,
           easing: 'linear',
           speed: 800,
           animateGradually: {
               enabled: true,
               delay: 150
           },
         }
       },

       xaxis: {
         type: 'numeric',
         tickAmount: 10,
         labels: {
           show: true,
           offsetY: -5,
           formatter: (value) => { return parseInt(value) + '' },
           style: {
              colors: '#ccc',
              fontSize: '11px'
           }
         },
         tooltip: {
           enabled: false
         },
         axisTicks: {
           show: true
         }
       },

       yaxis: {
         type: 'numeric',          
       },

       stroke: {
         show: true,
         curve: 'straight',
         width: 1,
         colors: ['#dbdbdb']
       },

       fill: {
         colors: this.getColors(),
         opacity: 1,
         type: 'solid'
       },
       
       grid: {
         show: true,
         borderColor: '#F0F0F0',
         strokeDashArray: 1
       },

       legend: {
         show: false
       },

       dataLabels: {
         enabled: false
       },

       tooltip: {
         enabled: false
       },

     }
   };
 },

 created() {
   this.initPerfil()
   this.updatePerfil()
 },

 mounted() {
 },

 methods: {
   initPerfil() {

     // ALTURA MAXIMA PERFIL    
     this.chartOptions = {...this.chartOptions, ...{
       yaxis: {
         max: this.maxElevation() + 300,
         tickAmount: 4,
         forceNiceScale: true,    
         floating: true,      
         labels: {
           align: 'left',
           offsetX: 10,            
           formatter: (value) => { 
             if(value > 0)
               return parseInt(value) + ' m' 
             },
           style: {
             colors: ['#DDDDDD']
           }
         }
       }
     }}

     // IMAGENES HITOS PERFIL
     this.chartOptions = {...this.chartOptions, ...{
         annotations: {
           points: this.getPointsTotales(this.timetable.getTimetable().data),
         }
       }
     }
   },

   maxElevation() {
     return Math.max.apply(Math, this.map.getProfileData().map(a => a[1]))
   },

   minElevation() {
     return Math.min.apply(Math, this.map.getProfileData().map(a => a[1]))
   },

   difElevation() {
     return this.maxElevation() - this.minElevation()
   },


   updatePerfil() {

     if(this.config.getConfig().general.type === 'itt' || this.config.getConfig().general.type === 'ttt')
       return

     var km_actual = parseInt(this.update.getSituation()[0].km)

     if(this.finishStageData()) {
       this.updatePerfilKm(this.map.getProfileData())
       this.resetGroups()
     } else {
       this.updatePerfilKm(this.map.getProfileData().slice(0, km_actual))
       this.updateGroups()
     }

   },

   updatePerfilKm(newData){
     
     const zoomState = this.getZoomState();

     var numero_series = this.series.length

     this.series[numero_series - 1] = {
       data: newData
     }

     setTimeout(() => {
         this.setZoomState(zoomState);
       }, 100); 
   },

   updateGroups() {

     this.chartOptions = {...this.chartOptions, ...{
         annotations: {
           points: this.getPointsTotales(this.timetable.getTimetable().data),
         }
       }
     }
     
   },

   resetGroups() {
     
     this.chartOptions = {...this.chartOptions, ...{
         annotations: {
           points: this.getPointsTotales(this.timetable.getTimetable().data),
         }
       }
     }

   },

   getColors() {

     var timetable_data = this.timetable.getTimetable().data
     var j = 0
     var colors = ['#ececec']

     for(var i = 0; i < timetable_data.length; i++) {
       if(timetable_data[i].hit == "-1") {
         
         var color = timetable_data[i].des
         colors.push(color)
         j++
       }          
     }

     colors.push(this.config.getConfig().general.color1)

     return colors

   },

   getSeries() {

     var timetable_data = this.timetable.getTimetable().data
     var series = []

     var serie = new Object()
     serie.data = this.map.getProfileData()
     series.push(serie)
     
     for(var i = 0; i < timetable_data.length; i++) {
       if(timetable_data[i].hit == "-1") {
         var start = parseFloat(timetable_data[i].cat)
         var finish = parseFloat(timetable_data[i].km)

         const clone_obj = [ ...this.map.getProfileData() ]

         serie = new Object()
         serie.data = clone_obj.slice(start, finish)
         series.push(serie)
       }
     }

     serie = new Object()
     serie.data = this.map.getProfileData().slice(0, 0)
     series.push(serie)

     return series
   },

   getPointsTotales(data_timetable) {

     const array_puntos = []
     const array_timetable = []
     var data = this.update.getSituation()
     var punto, marker, marker_horario, image, label, style

     marker_horario = new Object()
     marker_horario.size = 2

     for(var i=0;i<data_timetable.length;i++)
     {        
       let idh = data_timetable[i].idh
       let hor1 = data_timetable[i].hor1
       let hito = data_timetable[i].hit
       let categoria = data_timetable[i].cat
       let color = data_timetable[i].col
       let km_hito = data_timetable[i].kfr
       let km = data_timetable[i].km
       let elevation = data_timetable[i].ele

       if(hito == "-1")
         continue

       if(hito != "start" && hito != "finish" && idh != "") {
         punto = new Object()
         punto.x = parseFloat(km_hito)
         //punto.y = parseInt(elevation) + ((this.maxElevation() + 2400) / 10)
         punto.y = parseInt(elevation) + ((this.maxElevation() + 300) / 7)

         marker = new Object()
         marker.size = 0
         punto.marker = marker

         if(km_hito < data[0].km)
           marker.cssClass = 'opacity'

         image = {}
         //image.path = 'https://tracker.helmuga.cloud/images/profile_' + hito + '_' + categoria.toLowerCase() + '.png'
         image.path = require(`@/assets/images/profile_${hito}_${categoria.toLowerCase()}.png`);
         image.width = 18
         image.height = 18
         image.offsetY = 9
         punto.image = image
         
         array_puntos.push(punto)

         array_timetable.push({
           x: parseFloat(km),
           y: parseInt(elevation) + ((this.maxElevation() + 300) / 7),            
           marker: marker,
           label: {
             text: this.hora(hor1 * 1000 + this.update.getGeneral().started),
             style: {
               color: '#888',
               fontSize: '12px',
               textAnchor: 'middle',
               borderColor: '#888',
             },
           }
         })         

       }

       if(hito != "start" && hito != "finish" && idh == "") {    
         array_timetable.push({
           x: parseFloat(km),
           //y: parseInt(elevation) + ((this.maxElevation() + 300) / 7),
           y: parseInt(elevation),
           //marker: marker,              
           marker: marker_horario,              
           label: {
             text: this.hora(hor1 * 1000 + this.update.getGeneral().started),
             style: {
               color: '#888',
               fontSize: '12px',
               textAnchor: 'middle',
               borderColor: '#888',
             },
           }
         })
         
       }

     }

     // SITUATION

     /*if(!this.finishStageData() && this.startedRace()) {
       
       var max_elevation = this.maxElevation() + this.maxElevation() / 4.5

       for(i=0;i<data.length;i++)
       {
         let km = parseFloat(data[i].km)
         let ele = max_elevation
         let texto = parseInt(data[i].pos + 1).toString()
         let imagepath

         if(data[i].idg === "0")
           imagepath = "https://tracker.helmuga.cloud/images/time_p.png"
         else
           imagepath = "https://tracker.helmuga.cloud/images/time_" + (parseInt(data[i].pos) + 1) + ".png"

         punto = new Object()
         punto.x = km
         punto.y = ele

         marker = new Object()
         marker.size = 0
         punto.marker = marker

         image = {}
         image.path = imagepath
         image.width = 18
         image.height = 18
         image.offsetY = 9
         punto.image = image

         array_puntos.push(punto)
       }

     }*/

     let mergedArray

     if(this.showPoints)
       mergedArray = [...new Set([...array_puntos, ...array_timetable])]
     else
       mergedArray = array_puntos

     return mergedArray

   },

   hora(date) {
     moment.locale(this.$i18n.locale)
     //let formatString = 'HH:mm[' + this.$t('h') + ']'
     let formatString = 'HH:mm'
     return moment(parseInt(date)).format(formatString);
   },

   getPointsSituationXAxis(data) {
     const array_puntos = []

     /*

     for(var i=0;i<data.length;i++)
     {
       let ngr = data[i].ngr
       let km = parseFloat(data[i].km)

       var punto = new Object()
       punto.id = 'situation-groups-xaxis-' + i
       punto.x = km

       var label = new Object()
       label.text = ngr
       label.orientation = 'vertical'

       punto.label = label

       array_puntos.push(punto)
     }
*/
     return array_puntos
   },

   finishStageData() {

     var filtradoPorTipo = null

     filtradoPorTipo = this.update.getResultsFinal().filter(function(item) {
       return item.tip == 0
     })

     if(filtradoPorTipo.length > 0 && filtradoPorTipo[0].riders.length > 0)
       return true
     else
       return false

   },

   startedRace() {
     return this.update.getUpdate().situation[0].km > 0
   },

   togglePoints() {
     this.showPoints = !this.showPoints;

     // Actualizar las opciones del gráfico según el estado de `showPoints`
     this.chartOptions = {
       ...this.chartOptions,
       annotations: {
         points: this.getPointsTotales(this.timetable.getTimetable().data)
       }
     }
   },

   hayDatosHorarios() {
     const filtro = this.timetable.getTimetable().data.filter((item) => {
       return (item.idh === '' && item.hor1 !== '' && item.km != 0) || (item.idh !== '' && item.hor1 !== '')
     })

     if(filtro.length == 0)
       return false
     else
       return true
   },

   onChartMounted() {
     this.chartReady = true
     this.saveInitialRange()
   },

    moveChartRight() {
      let chart = this.$refs.perfil.chart

      if (chart) {
        const xaxis = chart.w.config.xaxis;
        const newMin = chart.w.globals.minX + this.shiftAmount;
        const newMax = chart.w.globals.maxX + this.shiftAmount;

        if (newMax < this.initialRange.max) {
          chart.updateOptions({
            xaxis: {
              min: newMin,
              max: newMax
            }
          });
        }

        if(newMin > 0)
          this.showMoveLeft = true
        else {     
          chart.updateOptions({
            xaxis: {
              min: 0,
              max: newMax
            }
          })
          this.showMoveLeft = false
        }


        if (newMax < this.initialRange.max)
          this.showMoveRight = true
        else
          this.showMoveRight = false
      }
    },


    moveChartLeft() {
      let chart = this.$refs.perfil.chart

      if (chart) {
        const xaxis = chart.w.config.xaxis;
        const newMin = chart.w.globals.minX - this.shiftAmount;
        const newMax = chart.w.globals.maxX - this.shiftAmount;

        if (xaxis.min > 0) {
          chart.updateOptions({
            xaxis: {
              min: newMin,
              max: newMax
            }
          })
        }

        if(newMin > 0)
          this.showMoveLeft = true
        else {     
          chart.updateOptions({
            xaxis: {
              min: 0,
              max: newMax
            }
          })
          this.showMoveLeft = false
        }


        if (newMax < this.initialRange.max)
          this.showMoveRight = true
        else
          this.showMoveRight = false
      }
    },

    saveInitialRange() {
      const chart = this.$refs.perfil.chart; // Accede a la instancia del gráfico
      if (chart) {
        this.initialRange.min = chart.w.globals.minX; // Obtiene el valor mínimo del eje X inicial
        this.initialRange.max = chart.w.globals.maxX; // Obtiene el valor máximo del eje X inicial
      } else {
        console.error('El gráfico no está disponible');
      }
    },

   zoomOut() {
     this.adjustZoom(1 + this.zoomFactor); // Aumentar el rango del eje X
   },

   zoomIn() {
     this.adjustZoom(1 - this.zoomFactor); // Reducir el rango del eje X
   },

   adjustZoom(factor) {
    const chart = this.$refs.perfil.chart; // Accede a la instancia del gráfico
    if (chart) {
      const xAxis = chart.w.globals.minX; // Obtiene el valor mínimo del eje X
      const xMax = chart.w.globals.maxX; // Obtiene el valor máximo del eje X
      const range = xMax - xAxis;

      let newMin = xAxis + (range * (1 - factor)) / 2;
      let newMax = xMax - (range * (1 - factor)) / 2;

      const initialRange = this.initialRange;
      if (newMin < this.initialRange.min) newMin = this.initialRange.min;
      if (newMax > this.initialRange.max) newMax = this.initialRange.max;

      chart.zoomX(newMin, newMax);

      this.zoomLevel *= factor;

      if(newMin > 0)
        this.showMoveLeft = true
      else {     
        chart.updateOptions({
          xaxis: {
            min: 0,
            max: newMax
          }
        })
        this.showMoveLeft = false
      }


      if (newMax < this.initialRange.max)
        this.showMoveRight = true
      else
        this.showMoveRight = false

     } else {
       console.error('El gráfico no está disponible para ajustar el zoom');
     }
   },

   getZoomState() {

     if (!this.chartReady)
       return

     const chart = this.$refs.perfil.chart; // Accede a la instancia del gráfico
     return {
       minX: chart.w.globals.minX,
       maxX: chart.w.globals.maxX
     };
   },

   setZoomState(zoomState) {

     if (!this.chartReady || typeof zoomState === 'undefined')
       return

     const chart = this.$refs.perfil.chart; // Accede a la instancia del gráfico
     chart.zoomX(zoomState.minX, zoomState.maxX); // Restaurar el zoom
   }


 }
};

</script>

<style scoped>

 .container_profile {
   position: relative;
 }

 .container_options {
   position: absolute;
   z-index: 999;
   top: 5px;
   right: 0;
   display: flex;
   margin-right: 5px;
 }

 .option {
   background-clip: padding-box;
   padding: 5px;
   background-color: white;
   cursor: pointer;
   color: #555;
   border-radius: 100%;
   display: flex;
   align-items: center;
   justify-items: center;
   height: 15px;
   width: 15px;
   -webkit-tap-highlight-color: transparent; /* Elimina el highlight azul en dispositivos móviles */
 }

 .option:hover {
  color: #222;
 }

 .icono {
   height: 17px;
   width: 17px;
   color: #DBDBDB;
   transition: color 0.2s ease;
 } 
 
 .icono:hover {
  color: #888;
 }

 .icono_move {
   font-size: 30px;
   color: #bbb;
   border-radius: 100%;   
   transition: color 0.2s ease;
   margin: 5px;
 }

 .icono_move:hover {
  color: #888;
 }

 @media (hover: none) {
  .icono:hover {
    color: #DBDBDB;
  }
  .icono_move:hover {
    color: #bbb;
  }

}

 .container_move_left {
  z-index: 99;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  width: 50px;
  cursor:pointer;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 1) 100%);
  -webkit-tap-highlight-color: transparent; 
 }

 .container_move_right {
  z-index: 99;
  position: absolute;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 10px;
  width: 50px;
  cursor:pointer;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 1) 100%);
  -webkit-tap-highlight-color: transparent; 
 }


</style>

<style>

 .opacity {
   opacity: 0.5;
 }


</style>