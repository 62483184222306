<template>
  <div 
    class="container_menuitem" 
    @click="classificationSelected(classification.tip)"
    :style="[classification.thumb == '' && classification.tip >= 0 ? {'justify-content': 'center', 'text-align': 'center'} : false]">
    <div v-if="classification.thumb != '' && classification.tip >= 0" style="height:30px;"><img class="thumb" :src="classification.thumb"></div>
    <div v-if="classification.thumb == '' && classification.tip == '-1'" style="height:20px;"><img class="thumb_icon" :src="require(`@/assets/images/finish.png`)"></div>
    <div v-if="classification.thumb == '' && classification.tip < -1 && classification.tip > -10" style="height:20px;"><img class="thumb_icon" :src="imageIntermedio(classification.num)"></div>
    <div v-if="classification.thumb != '' && classification.tip < -1 && classification.tip > -10" style="height:20px;"><img class="thumb_icon" :src="classification.thumb"></div>
    <div v-if="classification.thumb == '' && classification.tip == -9999" style="height:20px;"><img class="thumb_icon" :src="require(`@/assets/images/start.png`)"></div>
  </div>
</template>

<script>
  import { useResultsStore } from '@/store/results'

  export default {

    name: 'resultsmenu-item-mobile',

    props: {
      id: Number,
      classification: Object
    },

    setup() {
      const results = useResultsStore()

      return {
        results
      }
    },

    methods: {
      classificationSelected(tipo) {
        this.results.setFilterClassification(tipo)
      },      

      imageIntermedio(num) {
        return require(`@/assets/images/time_${num}.png`)
      },
    }

  }

</script>

<style scoped>

  .container_menuitem {
    height: 30px;
    display: flex;
    align-items: center;
  }

  .container_menuitem:hover {
    background: white;
  }

  .thumb {
    height: 30px;
  }

  .thumb_icon {
    height: 20px;
    width: 20px;
  }

  .text {
    margin: 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
  }

</style>
