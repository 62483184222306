<template>
  <div class="container_timetablepointinfo">
    <div class="title" style="margin-top:0px; padding-top: 0px;">
      <div class="imagen_hito"><img :src='imagenhito()'></div>
      <span>{{ this.getDescripcion() }}</span>
    </div>

    <div v-if="this.getPointData().hit !== 'start' && this.getPointData().hit !== 'finish'">
      <div class="data" v-if="this.getPointData().hit === '3'">
        <div class="data_linea">
          <div class="data_bloque">
            <span class="data_bloque_dato" style="height:30px"><img class="img_jersey" :src="getTipoClassification(this.getPointData().hit).thumb"></span>
          </div>
          <div class="data_bloque">
            <span class="data_bloque_dato">{{ this.getPointData().km }}</span>
            <span class="data_bloque_title">{{ $t('km_covered') }}</span>
          </div>
          <div class="data_bloque">
            <span class="data_bloque_dato">{{ (this.config.getConfig().general.distance - this.getPointData().km).toFixed(1) }}</span>
            <span class="data_bloque_title">{{ $t('km_to_finish') }}</span>
          </div>
        </div>

        <div class="data_linea">        
          <div class="data_bloque" v-if="this.getPointData().dio != 0">
            <span class="data_bloque_dato">{{ this.getPointData().dio }}</span>
            <span class="data_bloque_title">{{ $t('km_length') }}</span>
          </div>        
          <div class="data_bloque" v-if="this.getPointData().avo != 0">
            <span class="data_bloque_dato">{{ this.getPointData().avo }}</span>
            <span class="data_bloque_title">{{ $t('perc_average') }}</span>
          </div>        
          <div class="data_bloque" v-if="this.getPointData().ele != null">
            <span class="data_bloque_dato">{{ this.getPointData().ele }}</span>
            <span class="data_bloque_title">{{ $t('m_elevation') }}</span>
          </div>
        </div>
      </div>

      <div class="data" v-if="this.getPointData().hit !== '3' && this.getPointData().cat !== '2'">
        <div class="data_linea">
          <div class="data_bloque">
            <span class="data_bloque_dato" style="height:30px"><img class="img_jersey" :src="getTipoClassification(this.getPointData().hit).thumb"></span>
          </div>
          <div class="data_bloque">
            <span class="data_bloque_dato">{{ this.getPointData().km }}</span>
            <span class="data_bloque_title">{{ $t('km_covered') }}</span>
          </div>
          <div class="data_bloque">
            <span class="data_bloque_dato">{{ (this.config.getConfig().general.distance - this.getPointData().km).toFixed(1) }}</span>
            <span class="data_bloque_title">{{ $t('km_to_finish') }}</span>
          </div>
        </div>

        <div class="data_linea">
          <div class="data_bloque" v-if="this.getPointData().ele != null">
            <span class="data_bloque_dato">{{ this.getPointData().ele }}</span>
            <span class="data_bloque_title">{{ $t('m_elevation') }}</span>
          </div>
        </div>
      </div>   
      <div v-if="typeof this.dataIntermediate()[0] !== 'undefined' && this.dataIntermediate()[0].riders.length > 0 && this.dataIntermediate()[0].riders[0].dor !== null">
        <div class="separator" style="border-bottom:0px">{{ $t('results') }}</div>
          <ResultsListItem
            v-for="(intermediate, index) in this.dataIntermediate()"
            :key="index"
            :intermediate="intermediate"
            :showTitulo="'0'"
            class="point"
          ></ResultsListItem>
      </div>      

      <div v-if="typeof this.update.getGeneral().weather !== 'undefined' && this.update.getGeneral().weather.length > 0">
        <div class="separator" style="margin-bottom: 12px">{{ $t('weather') }}</div>
        <Weather style="border:0" :point="this.getPointData().kfr.toString()"></Weather>
      </div>

      <div v-if="this.getPointData().ksr != 0 && this.getPointData().kfr != 0">
        <div v-if="0 && typeof this.getPointData().img !== 'undefined'">
          <div class="separator" style="margin-bottom: 20px">{{ $t('profile') }}<span v-if="this.getPointData().hit !== '3'">&nbsp;-&nbsp;{{ $t('last_3km') }}</span></div>
          <div class="climb_img" v-if="this.getPointData().img !== ''" @click="this.clickImageDetail()">
            <img :src="this.getPointData().img">
          </div>
          <ProfileClimb 
            v-if="this.getPointData().img === ''"
            :km_inicio="parseFloat(this.getPointData().ksr)"
            :km_final="parseFloat(this.getPointData().kfr)"
          ></ProfileClimb>
        </div>

        <div class="separator" style="margin-bottom: 0px">{{ $t('map') }}<span v-if="this.getPointData().hit !== '3'">&nbsp;-&nbsp;{{ $t('last_3km') }}</span></div>
        <TimetablePointMap
          :km_inicio="parseFloat(this.getPointData().ksr)"
          :km_final="parseFloat(this.getPointData().kfr)"
          :data="this.getPointData()"
        ></TimetablePointMap>      
      </div>      
    
    </div>

    <div v-if="this.getPointData().hit === 'start'">      
      
      <div v-if="typeof this.update.getGeneral().weather !== 'undefined' && this.update.getGeneral().weather.length > 0">
        <div class="separator" style="margin-bottom: 12px">{{ $t('weather') }}</div>
        <Weather style="border:0" point='start' />
      </div>

      <div v-if="0">
        <div class="separator" style="margin-bottom: 20px">{{ $t('profile') }}&nbsp;-&nbsp;{{ $t('first_3km') }}</div>
        <ProfileClimb 
          :km_inicio="parseFloat(0)"
          :km_final="parseFloat(3)"
        ></ProfileClimb>
      </div>

      <div class="separator" style="margin-bottom: 0px">{{ $t('map') }}&nbsp;-&nbsp;{{ $t('first_3km') }}</div>
      <TimetablePointMap
        :km_inicio="parseFloat(0)"
        :km_final="parseFloat(3)"
        :data="this.getPointData()"
      ></TimetablePointMap>
    </div>

    <div v-if="this.getPointData().hit === 'finish'">      

      <div v-if="typeof this.update.getGeneral().weather !== 'undefined' && this.update.getGeneral().weather.length > 0">
        <div class="separator" style="margin-bottom: 12px">{{ $t('weather') }}</div>
        <Weather style="border:0" point='finish' />
      </div>

      <div v-if="0">
        <div class="separator" style="margin-bottom: 20px">{{ $t('profile') }}&nbsp;-&nbsp;{{ $t('last_3km') }}</div>
        <ProfileClimb 
          :km_inicio="parseFloat(parseFloat(this.getPointData().ksr))"
          :km_final="parseFloat(parseFloat(this.getPointData().kfr))"
        ></ProfileClimb>
      </div>

      <div class="separator" style="margin-bottom: 0px">{{ $t('map') }}&nbsp;-&nbsp;{{ $t('last_3km') }}</div>
      <TimetablePointMap
        :km_inicio="parseFloat(parseFloat(this.getPointData().ksr))"
        :km_final="parseFloat(parseFloat(this.getPointData().kfr))"
        :data="this.getPointData()"
      ></TimetablePointMap>
    </div>

  </div>
</template>


<script>
  import { useConfigStore } from '@/store/config'
  import { useUpdateStore } from '@/store/update'
  import { usePagesStore } from '@/store/pages'
  import { useTimetableStore } from '@/store/timetable'
  import ResultsListItem from '@/components/ResultsListItem'
  import ProfileClimb from '@/components/ProfileClimb'
  import TimetablePointMap from '@/components/TimetablePointMap'
  import Weather from '@/components/Weather'

  export default {
    name: 'timetablepointinfo',

    setup() {
      const pages = usePagesStore()
      const timetable = useTimetableStore()
      const update = useUpdateStore()
      const config = useConfigStore()

      return {
        pages,
        timetable,
        update,
        config
      }
    },

    methods: {

      getDescripcion() {

        let idhorario = this.pages.data.selectedTimetablePoint

        switch(idhorario) {
          case "start":
            return "Start"
          
          case "finish":
            return "Finish"

          default:
            return this.getPointData().des
            
        }
        
      },

      imagenhito() {

        switch(this.getPointData().hit) {

          case "start":
          case "finish":
            return require(`@/assets/images/${this.getPointData().hit.toLowerCase()}.png`)

          default:
            return require(`@/assets/images/profile_${this.getPointData().hit}_${this.getPointData().cat.toLowerCase()}.png`)
            
        }
        
      },

      getPointData() {
        let idhorario = this.pages.data.selectedTimetablePoint
        return this.timetable.getTimetable().data.filter((point) => point.idh === idhorario)[0]        
      },

      dataIntermediate() {
        let idhorario = this.pages.data.selectedTimetablePoint
        let result = this.update.getResultsIntermediate().filter((item) => item.idh == idhorario)

        if(result.length > 0)
          return result
        else
          return []
      },
      
      getTipoClassification(tipo) {
        return this.config.getClassifications().filter((item) => item.tip === tipo)[0]
      },

      clickImageDetail() {
        if(!this.isMobile()) {
          this.pages.setImageDetail(this.getPointData().img)
          this.pages.setImageDetailText("")
        }
          
      },
            
      isMobile() {
        return screen.width < 768
      },


    },

    components: {
      ResultsListItem,
      ProfileClimb,
      TimetablePointMap,
      Weather
    },


  }
</script>


<style scoped>

  @media (max-width: 768px) {

    .container_timetablepointinfo {
      margin-top: 40px !important;
      margin-bottom: 10px !important;
      padding-top: 0px !important;
    }
  }

  .container_timetablepointinfo {
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
    padding-top: 40px;
  }

  .title {
    display: flex;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
  }
    
  .imagen_hito {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .imagen_hito img {
    height: 20px;
    width: 20px;
  }

  .separator {
    display: flex !important;
    width: calc(100% - 2px) !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    margin-top: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 15px;
    justify-content: center;
  }

  .data {
    display:flex;
    flex-direction: column;
    align-items: center;

  }

  .climb_img img {
    width: 100%;
    cursor: pointer;
  }

  .data_linea {
    display: flex;
    margin: 12px 0;
    width: 100%;
    justify-content: space-between;
  }

  .data_bloque {    
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .img_jersey {
    height: 30px;
  }

  .data_bloque_dato {
    color: #555;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .data_bloque_title {
    font-size: 12px;
    color: #AAA;
    text-align: center;
  }

</style>