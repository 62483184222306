<template>
  <div class="container" @click="clickTeam" >
    <div v-if="team.uci" class="bib">{{ team.uci }}</div>
    <div v-if="team.thu" class="thumb"><img :src="team.thu"></div>
    <div v-if="team.thu == ''" class="thumb"><img src="https://resources.helmuga.cloud/maillots/classifications/white.png"></div>
    <div class="container_name">
      <div class="country" v-if="team.pai"><img :src="require(`@/assets/flags/${team.pai}.png`)" /></div>
      <div class="name">{{ team.nom }}</div>
      <!--<span v-if="team.nom == 'Bahrain Victorious'" class="bike"><img src="https://tracker.helmuga.cloud/resources/sponsors/merida.svg"></span>-->
    </div>
  </div>
</template>

<script>
  import { usePagesStore } from '@/store/pages'
  import { useStartlistStore } from '@/store/startlist'

  export default {
    name: 'teams-item',

    props: {
        id: Number,
        team: Object
    },

    setup() {
      const pages = usePagesStore()
      const startlist = useStartlistStore()

      return {
        pages,
        startlist
      }
    },

    methods: {
      
      clickTeam() {        
        
        this.startlist.setSelectedTeam(this.team)

        this.pages.setPopupType('teams')

        if(this.isMobile()) {
          this.pages.setVisiblePopup(true)
        }
      },      

      isMobile() {
        return screen.width < 768
      },

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "user":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "startlist":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "star":
            return '<svg class="icon" style="stroke:#AAA" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 2.3607 7.26543h7.6393l-6.1803 4.49027 2.3606 7.2654-6.1803-4.4902-6.18034 4.4902 2.36068-7.2654-6.18034-4.49027h7.63932z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
        
      }
    }

  }
</script>

<style scoped>

  @media (max-width: 768px) {

    .country {
      display: none !important;
    }

  }


  .container {
    display: flex;
    grid-template-columns: 50px 50px auto;
    align-items: center;
    padding: 12px 6px;
    font-size: 14px;
    background: #FAFAFA;
    cursor: pointer;
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }

  .container:first-child {
    border-top: 1px solid #dbdbdb;
  }

  .container_name {
    display: flex;
    flex: 1;
    align-items: center;
  }


  .bib {
    text-align: center;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bold;
    color:#777;
    width: 50px;
  }
  
  .thumb {
    display: flex;
    justify-content: center;
    width: 50px;
  }

  .thumb img {
    height:35px;    
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
  }

  .country{
    height:13px;
    margin-left: 10px;
    border: 1px solid #eee;
  }

  .country img {
    height:13px;
  }

  .icon {
    text-align: center;
  }
  
  .name {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-left: 10px;
  }

  .bike {
    display: flex;
    height: 10px;
  }

  .bike img {
    margin-left: 5px;
    height: 10px;
  }


</style>