<template>
  <div class="menu_option" @click="setPageVisible(data)" :style="color1">
  <div class="icon_menu" v-html="iconData(data)"></div>
  <!--<span>{{ $t(data) }}</span>-->
  </div>
</template>

<script>
  import { useConfigStore } from '@/store/config'
  import { usePagesStore } from '@/store/pages'
  import { useUpdateStore } from '@/store/update'
  import { useStartlistStore } from '@/store/startlist'

  export default {

    name: 'menu-item',

    props: {
      id: Number,
      data: String
    },

    data () {
      return {
        fillicon: this.config.getConfig().general.color2
      }
    },

    setup() {
      const config = useConfigStore()
      const pages = usePagesStore()
      const update = useUpdateStore()
      const startlist = useStartlistStore()

      return {
        config,
        pages,
        update,
        startlist,
      }
    },

    computed: {

      color1() {
        return {
          //"color":  `${this.config.getConfig().general.color1}`
          "color": "#f0f0f0"
        }
      },

      strokeColor() {
        return {
          //"stroke":  `${this.config.getConfig().general.color1}`
          "stroke": "#f0f0f0"
        }
      },

    },

    methods: {

      setPageVisible(page) {
        if(page != "startlist")
          this.pages.setPopupType('')
        else {
          if(this.pages.getPopupType() === '') {
            this.pages.setPopupType('riders')
          }
        }
          
        this.pages.setVisiblePopup(false)
        this.pages.setPageVisible(page)
        this.pages.setFullScreenMap(false)
        this.pages.setMapType('standard')
        window.scrollTo(0,0)
      },      

      strokeColorHex() {
        return this.config.data.general.fore_color
      },

      iconData(panel) {

        // https://iconduck.com/sets/basicons-icon-set

        switch (panel) {
          case "dashboard":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m3 10.25v9.75c0 .5523.44771 1 1 1h4.42857c.55229 0 1-.4477 1-1v-6.7143h5.14283v6.7143c0 .5523.4477 1 1 1h4.4286c.5523 0 1-.4477 1-1v-9.75c0-.31476-.1482-.61115-.4-.8l-8.6-6.45-8.6 6.45c-.25181.18885-.4.48524-.4.8z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'
          
          case "startlist":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="9" cy="7" r="4"/><path d="m2 21v-4c0-1.1046.89543-2 2-2h10c1.1046 0 2 .8954 2 2v4"/><path d="m16 3c.8604.2203 1.623.7207 2.1676 1.42231s.8402 1.56452.8402 2.45269-.2956 1.75108-.8402 2.45269-1.3072 1.20201-2.1676 1.42231"/><path d="m19 15h1c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "results":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m8 6h8m-8 4h8m-8 4h3m-5 8h12c1.1046 0 2-.8954 2-2v-16c0-1.10457-.8954-2-2-2h-12c-1.10457 0-2 .89543-2 2v16c0 1.1046.89543 2 2 2z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          case "tracker":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m6.0325 15.2873 5.9676 6.7127 5.9676-6.7127c4.577-5.1485.9217-13.2873-5.9676-13.2873-6.88931 0-10.54461 8.1388-5.9676 13.2873z"/><circle cx="12" cy="10" r="3"/></g></svg>'
            
          case "info":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="m12 6.01953v-.01953"/><path d="m12 10v8"/></g></svg>'
          
          case "user":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="7" r="4"/><path d="m4 21v-4c0-1.1046.89543-2 2-2h12c1.1046 0 2 .8954 2 2v4"/></g></svg>'
          
          case "star":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 2.3607 7.26543h7.6393l-6.1803 4.49027 2.3606 7.2654-6.1803-4.4902-6.18034 4.4902 2.36068-7.2654-6.18034-4.49027h7.63932z" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          case "commentary":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m7 7h10"/><path d="m7 11h4"/><path d="m3 5v15.7929c0 .4454.53857.6685.85355.3535l3.85356-3.8535c.18753-.1875.44189-.2929.7071-.2929h10.58579c1.1046 0 2-.8954 2-2v-10c0-1.10457-.8954-2-2-2h-14c-1.10457 0-2 .89543-2 2z"/></g></svg>'

          case "radiotour":
            return '<svg class="icon_menu" style="stroke:' + this.strokeColorHex() + '" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m2 14.5v6.5c0 .5523.44772 1 1 1h3.71428c.55229 0 1.00001-.4477 1.00001-1v-5.5c0-.5523-.44772-1-1-1zm0 0v-2.5c0-5.52285 4.47715-10 10-10 5.5228 0 10 4.47715 10 10v2.5m0 0v6.5c0 .5523-.4477 1-1 1h-3.7143c-.5523 0-1-.4477-1-1v-5.5c0-.5523.4477-1 1-1z" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>'

          default:
            break;
        }
        
      },

      isMobile() {
        return screen.width < 768
      },

    },

  }

</script>

<style scoped>

  .menu_option {
    cursor: pointer;
    color: #DDD;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px 10px;
    transition: 0.3s;
    letter-spacing: 1px;
  }

  .menu_option:hover {
    color: #FFF;
  }

</style>

<style>

  .icon_menu {
    fill: v-bind(fillicon)
  }

  .icon {
    fill: white;
  }

</style>