<template>
  <div 
    class="container_menuitem" 
    @click="classificationSelected(classification.tip)"
    :style="[classification.thumb == '' && classification.tip >= 0 ? {'justify-content': 'center', 'text-align': 'center'} : false]">
    <div v-if="classification.thumb != '' && classification.tip >= 0" style="height:30px;"><img class="thumb" :src="classification.thumb"></div>
    <div v-if="classification.thumb == '' && classification.tip == '-1'" style="height:20px;"><img class="thumb_icon" :src="require(`@/assets/images/finish.png`)"></div>
    <div v-if="classification.thumb == '' && classification.tip < -1 && classification.tip > -10" style="height:20px;"><img class="thumb_icon" :src="imageIntermedio(classification.num)"></div>
    <div v-if="classification.thumb != '' && classification.tip < -1 && classification.tip > -10" style="height:20px;"><img class="thumb_icon" :src="classification.thumb"></div>
    <div v-if="classification.thumb == '' && classification.tip == -9999" style="height:20px;"><img class="thumb_icon" :src="require(`@/assets/images/start.png`)"></div>
    <div 
    class="text"
    :style="[classification.thumb == '' ? {'text-align': 'center'} : false]">{{ getNombreClasificacion() }}</div>
  </div>
</template>

<script>
  import { useResultsStore } from '@/store/results'

  export default {

    name: 'resultsmenu-item',

    props: {
      id: Number,
      classification: Object
    },

    setup() {
      const results = useResultsStore()

      return {
        results
      }
    },

    methods: {
      classificationSelected(tipo) {
        this.results.setFilterClassification(tipo)
      },      

      imageIntermedio(num) {
        return require(`@/assets/images/time_${num}.png`);
      },

      getNombreClasificacion() {
        if(typeof this.classification.langs[this.$i18n.locale] !== 'undefined')
          return this.classification.langs[this.$i18n.locale]
        else
          return this.$t('ranking_' + this.classification.tip)
      },
    }

  }

</script>

<style scoped>

  .container_menuitem {
    display: flex;
    align-items: center;
    background: #fafafa;
    border: 1px solid #dbdbdb;
    flex-basis: 150px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    cursor: pointer;    
    justify-content: space-between;
    transition: 0.3s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;  
    -ms-user-select: none; 
    -moz-user-select: none; 
    -o-user-select:none;
    user-select:none;
    border-radius: 5px;
  }

  .container_menuitem:hover {
    background: white;
  }

  .thumb {
    height: 30px;
    margin-left: 10px;
  }

  .thumb_icon {
    height: 20px;
    margin-left: 10px;
  }

  .text {
    margin: 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
  }

</style>
